import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import {
  Register,
  Login,
  AllProducts,
  Title,
  SingleProductView,
  AdminPage,
  AdminProducts,
  AdminUsers,
  AdminUpdate,
  AdminOrders,
  Cart,
  CreateForm,
  MyAccount,
  EditUser,
  Contact,
  About,
  Newsletter,
  Next,
  Shipping,
  ComingSoon,
  CreateSoon,
  News,
  CreateNews,
  NewTitleNav
} from '../components';

import { BASE_URL } from '../constants';

import Footer from './Footer';

import Home from './home';

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import axios from 'axios';



const App = () => {

  const [token, setToken] = useState(localStorage.getItem("token"));
  const [user, setUser] = useState({});
  const [isAdmin, setIsAdmin] = useState(localStorage.getItem("token"));
  const [showPopup, setShowPopup] = useState(false);

  const [clientId, setClientId] = useState('');


  const [newsletter, setNewsletter] = useState({
    firstName: "",
    lastName: "",
    email: ""
  });

  const [subscribed, setSubscribed] = useState(false);

  const [isHome, setIsHome] = useState(true);

  function disablePopup() {
    localStorage.setItem("ella subscribed", true)
  }


  function setHomeTrue() {
    setIsHome(true);
  }

  function setHomeFalse() {
    setIsHome(false);
  }

  useEffect(() => {
    const popupShown = localStorage.getItem("ella subscribed");
    if (!popupShown) {
      setTimeout(() => {
        setShowPopup(true);
      }, 2000)
    }
  }, [])


  // useEffect( () => {
  //   const somethingFromStorage = localStorage.getItem("U can haz website");
  //   if(!somethingFromStorage){
  //     setShowMeme(true);
  //     localStorage.setItem("U can haz website", true)
  //   } 

  // }, [])


  // As soon as page hits we will grab the current logged in users token and set it on state.
  useEffect(() => {

    const storedToken = localStorage.getItem("token");
    const currentUser = localStorage.getItem("user");
    const admin = localStorage.getItem("isAdmin");
    if (storedToken) {
      setToken(storedToken);
      setUser(currentUser);
      setIsAdmin(admin);
    }
  }, [token, user, isAdmin]);


  function handleChange(e) {
    setNewsletter({
      ...newsletter, [e.target.name]: e.target.value
    })

  }

  async function handleNewsletterSubmit(event) {
    event.preventDefault();

    let options = {
      method: "POST",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        firstName: newsletter.firstName,
        lastName: newsletter.lastName,
        email: newsletter.email
      }
      )
    }

    fetch(`${BASE_URL}/newsletter`, options)

      .then(response => response.json())
      .then((result) => {
        setSubscribed(true);
        setTimeout(() => {
          setShowPopup(false);
        }, 2000)
        disablePopup();

      })
      .catch(console.error);

  }

  useEffect(() => {
    axios.get(`${BASE_URL}/paypal`).then((response) => {
      // console.log("PAYPAL: ", response.data);
      setClientId(response.data);
    })

  }, [clientId])

  const initialOptions = {
    "client-id": clientId,
    currency: "USD",
    components: "buttons"
  };


  return (
    <div className='theWholeDamnThing'>
      <div className='theWholeDamnImage'></div>
      <div className='allTheContent'>
        <Router>
          <NewTitleNav token={token} setToken={setToken} isAdmin={isAdmin} setIsAdmin={setIsAdmin} setUser={setUser} isHome={isHome} />


          <div className="page-container">
            {/* <Route path="/register" render={(routeProps) => <Register {...routeProps} setToken={setToken}
            setUser={setUser}
            setIsAdmin={setIsAdmin} />} /> */}
            <Route path="/login" render={(routeProps) => <Login {...routeProps} setToken={setToken}
              setUser={setUser}
              setIsAdmin={setIsAdmin} />} />
            {/* <Route path="/users/:userId/orders" render={(routeProps) => <UserOrders {...routeProps} token={token} user={user} />} /> */}
            {/* <Route path="/users/:userId/cart" render={(routeProps) => <UserCart {...routeProps} token={token} user={user} />} /> */}
            <Route exact path="/books" render={(routeProps) => <AllProducts {...routeProps} token={token} setHomeFalse={setHomeFalse} />} />
            <Route exact path="/books/:productId" render={(routeProps) => <SingleProductView {...routeProps} token={token} setToken={setToken} />} />
            <Route exact path="/cart" render={(routeProps) => <Cart {...routeProps} token={token} setToken={setToken} setHomeFalse={setHomeFalse} />} />
            {/* <Route path="/orders/:orderId" render={(routeProps) => <SingleOrder {...routeProps} token={token} user={user} />} /> */}
            {/* <Route path="/reviews/:productId" render={(routeProps) => <Reviews {...routeProps} token={token} user={user} />} /> */}
            <Route path="/admin" render={(routeProps) => <AdminPage {...routeProps} token={token} />} />
            <Route path="/admin-create" render={(routeProps) => <CreateForm {...routeProps} />} />
            <Route path="/admin-comingsoon" render={(routeProps) => <CreateSoon {...routeProps} />} />

            <Route path="/update/:productId" render={(routeProps) => <AdminUpdate {...routeProps} />} />
            <Route path="/admin-products" render={(routeProps) => <AdminProducts {...routeProps} />} />
            <Route path="/admin-users" render={(routeProps) => <AdminUsers {...routeProps} />} />
            <Route path="/admin-orders" render={(routeProps) => <AdminOrders {...routeProps} />} />
            <Route path="/admin-addnews" render={(routeProps) => <CreateNews {...routeProps} />} />

            <Route path="/account" render={(routeProps) => <MyAccount {...routeProps} token={token} />} />
            <Route path="/" exact render={(routeProps) => <Home {...routeProps} setHomeTrue={setHomeTrue} />} />
            <Route path="/contact" render={(routerProps) => <Contact {...routerProps} isAdmin={isAdmin} />} />
            <Route path="/about" render={(routerProps) => <About {...routerProps} isAdmin={isAdmin} setHomeFalse={setHomeFalse} />} />
            <Route path="/newsletter" render={(routerProps) => <Newsletter {...routerProps} />} />

            {/* <Route path="/comingsoon" render={(routerProps) => <ComingSoon newsletter={newsletter} handleChange={handleChange} handleNewsletterSubmit={handleNewsletterSubmit} {...routerProps} setHomeFalse={setHomeFalse} />} /> */}

            <Route path="/next" render={(routerProps) => <Next {...routerProps} />} />
            <Route path="/news" render={(routerProps) => <News {...routerProps} isAdmin={isAdmin} setHomeFalse={setHomeFalse} />} />


            <Route path="/editUser" render={(routeProps) => <EditUser {...routeProps} token={token} user={user} />} />

            <Route path="/shipping" render={(routeProps) => <Shipping {...routeProps} options={initialOptions} />} />

          </div>

          <Footer newsletter={newsletter} handleChange={handleChange} handleNewsletterSubmit={handleNewsletterSubmit} />

          {/* <Modal
            size="lg"
            show={showPopup}
            onHide={() => setShowPopup(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            className='comingSoonModalPopup'>
            <Modal.Header closeButton>
 

            </Modal.Header>
            <Modal.Body closeButton>
              <div className='comingsoon-popup'>
                <div className='popup-image-div'>
                  <img src='https://i.imgur.com/5DOOJxO.png' className="popupImage" />
                </div>
                <div className='newsletter-form'>
                  <p className='popupDeck'>{!subscribed ? "Subscribe for news about Want, exclusive content, giveaways, and more!" : "Thanks for subscribing!"}</p>
                  {!subscribed && <form onSubmit={handleNewsletterSubmit}>
                    <div className='popupFormInputsDiv'>
                      <div>
                        <label><b>Name</b></label>

                        <input onChange={handleChange} value={newsletter.lastName} name="lastName" type="text" className="form-control popup-input" placeholder="Name" required />
                      </div>
                      <div>
                        <label><b>Email</b></label>
                        <input onChange={handleChange} value={newsletter.email} name="email" type="text" className="form-control popup-input" placeholder="Email" required />
                      </div>
                      <button type="submit" className="button">Submit</button>

                    </div>
                  </form>}
                </div>

              </div>


            </Modal.Body>
          </Modal> */}

        </Router>

      </div>

    </div>

  );

}

//get a couple of different sizes of images for popup logo, have conditionals based on screen width
//get actual source image dimensions; have one that is wider, one for mobile--maybe a square
//something on the user table, subscribed true/false-- after you send newslettwe request, change so they are subscribed


export default App;