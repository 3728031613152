import React from 'react';
import { useState, useEffect } from 'react';
import { createProduct } from '../api';
import { Link } from 'react-router-dom';

import ProductDescriptionEdit from './ProductDescriptionEdit';
import axios from 'axios';

import { BASE_URL } from '../constants';

import UploadAssist from './UploadAssist';


const CreateForm = ({ history }) => {

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState("");
    const [invQty, setInvQty] = useState("");
    const [imgUrl, setImgUrl] = useState("");
    const [series, setSeries] = useState([]);
    const [seriesId, setSeriesId] = useState("");
    const [seriesName, setSeriesName] = useState('');
    const [volNum, setVolNum] = useState("");
    const [kindleUrl, setKindleUrl] = useState("");
    const [audioUrl, setAudioUrl] = useState("");
    const [paperUrl, setPaperUrl] = useState("");
    const [graph, setGraph] = useState("");
    const [allGraphs, setAllGraphs] = useState([]);
    const [quote, setQuote] = useState("");
    const [helpClick, setHelpClick] = useState(false)


    useEffect(() => {
        axios.get(`${BASE_URL}/series`).then((response) => {
            setSeries(response.data)
        })
    }, [])

    function addNewGraph() {
        setAllGraphs([...allGraphs, graph]);
        setGraph('');
    }

    function updateGraph(originalText, newText) {
        const updatedGraphs = allGraphs.map((item, index, allGraphs) => {
            if (originalText === item) {
                item = newText
            }
            return item
        })
        setAllGraphs(updatedGraphs)
    }

    function removeParagraph(originalText) {
        const updatedGraphs = allGraphs.filter((item) => {
            return item != originalText
        })
        setAllGraphs(updatedGraphs)
    }


    return (
        <div className="form-group w-100">
            <br />
            <div className='createProductTopButtons'>
                <div className='goBackButtonDiv'>
                    <Link to="/admin" className='createProductGoBackButton'>
                        GO BACK
                    </Link>
                </div>
                <div className='updateHelp'>
                    {!helpClick ? <button onClick={() => setHelpClick(true)} className='createProductGoBackButton'>Help</button>
                        : <button onClick={() => setHelpClick(false)} className='createProductGoBackButton'>X</button>}

                </div>
            </div>


            {helpClick && <div className='uploadAssistCreateProductDiv'>
                <UploadAssist />
            </div>}

            <div className='create-product-form-container'>
                <form
                    className="centered m-3 w-100 create-product-form"
                    onSubmit={async (event) => {
                        event.preventDefault();
                        console.log("PRODUCT SUBMITTED!");
                        const inSeries = seriesName ? true : false;
                        try {
                            await createProduct(name, description, allGraphs, quote, price, invQty, imgUrl, seriesId, volNum, kindleUrl, audioUrl, paperUrl, inSeries, seriesName);
                            history.push("/books")
                        }
                        catch (error) {
                            console.error('ERROR with submission for creating a new product!!! 🤦‍♂️');
                            throw error;
                        }
                    }}>
                    <div className='create-product-form-content-div'>
                        <div className="form-group create-product-form-item">
                            <label className=''><b>Product Name</b></label>
                            <br></br>
                            <input
                                type="text"
                                className="form-control "
                                placeholder="Product name..."
                                required
                                onChange={(event) => setName(event.target.value)}
                                value={name}
                            />
                            <br></br>
                        </div>

                        <div className="form-group create-product-form-item">
                            <label className=''><b>Description</b></label>
                            <br></br>
                            <input
                                type="text"
                                className="form-control "
                                placeholder="Description..."
                                required
                                onChange={(event) => setDescription(event.target.value)}
                                value={description}
                            />
                            <br></br>
                        </div>

                        <div className="form-group create-product-form-item">
                            <label className=''><b>Quote</b></label>
                            <br></br>
                            <input
                                type="text"
                                className="form-control "
                                placeholder="Quote..."
                                onChange={(event) => setQuote(event.target.value)}
                                value={quote}
                            />
                            <br></br>
                        </div>

                        <div className="form-group create-product-form-item paragraphs-div">
                            <label className='create-product-paragraphs-head'><b>Paragraphs</b></label>
                            <br></br>
                            <textarea
                                className="form-control "
                                placeholder="Add a paragraph to your summary"
                                onChange={(event) => setGraph(event.target.value)}
                                value={graph}
                            />
                            {allGraphs.length < 10 ? <button className="add-graph-button" onClick={addNewGraph}>Add Graph</button> : <h4 className='paragraph-limit-head'>You've reached your paragraph limit</h4>}
                            <br></br>
                            <div className='added-graphs-div'>
                                <div className="border">
                                    <h4 className="graphs-head">Graphs</h4>
                                    {allGraphs.map((graph, index) => {
                                        return <ProductDescriptionEdit graph={graph} key={index} className='editedGraphs' updateGraph={updateGraph} removeParagraph={removeParagraph} />

                                    })}
                                </div>

                            </div>

                        </div>


                        <div className="form-group create-product-form-item">
                            <label className=''><b>Price</b></label>
                            <br></br>
                            <input
                                type="text"
                                className="form-control "
                                placeholder="Price..."
                                required
                                onChange={(event) => setPrice(event.target.value)}
                                value={price}
                            />
                            <br></br>
                        </div>

                        <div className="form-group create-product-form-item">
                            <label className=''><b>Quantity In Inventory</b></label>
                            <br></br>
                            <input
                                type="text"
                                className="form-control "
                                placeholder="Inventory quantity..."
                                required
                                onChange={(event) => setInvQty(event.target.value)}
                                value={invQty}
                            />
                            <br></br>
                        </div>

                        <div className="form-group create-product-form-item">
                            <label className=''><b>Image URL</b></label>
                            <br></br>
                            <input
                                type="text"
                                className="form-control "
                                placeholder="Image URL..."
                                onChange={(event) => setImgUrl(event.target.value)}
                                value={imgUrl}
                            />
                            <br></br>
                        </div>

                        {/* <div className="form-group w-50 series-selector">
                        <label className=''><b>In Series?</b></label>
                        <br></br>
                        <select
                            className=''
                            onChange={(event) => setSeriesId(event.target.value)}
                        >
                            <option value="">Choose Series</option>
                            {series.map((item) => {
                                return <option key={item.id} value={item.id}>{item.name}</option>


                            })}
                        </select>
                        <br></br>
                    </div> */}

                        <div className="form-group create-product-form-item series-selector">
                            <label className=''><b>Series</b></label>
                            <br></br>
                            <select
                                className=''
                                onChange={(event) => {
                                    setSeriesId(event.target.value);
                                    const seriesItem = series.find((item)=>item.id==event.target.value);
                                    console.log("ON CHANGE SERIES NAME: ", seriesItem)
                                    setSeriesName(seriesItem.name)
                                }}
                            >
                                <option value="">Choose Series</option>
                                {series.map((item) => {
                                    return <option key={item.id} value={item.id}>{item.name}</option>

                                })}
                            </select>
                            <br></br>
                        </div>

                        <div className="form-group create-product-form-item">
                            <label className=''><b>Volume</b></label>
                            <br></br>
                            <input
                                type="text"
                                className="form-control "
                                placeholder="Volume number..."
                                required
                                onChange={(event) => setVolNum(event.target.value)}
                                value={volNum}
                            />
                            <br></br>
                        </div>

                        <div className="form-group create-product-form-item">
                            <label className=''><b>Kindle Link</b></label>
                            <br></br>
                            <input
                                type="text"
                                className="form-control "
                                placeholder="Kindle link..."
                                onChange={(event) => setKindleUrl(event.target.value)}
                                value={kindleUrl}
                            />
                            <br></br>
                        </div>

                        <div className="form-group create-product-form-item">
                            <label className=''><b>Audio Link</b></label>
                            <br></br>
                            <input
                                type="text"
                                className="form-control "
                                placeholder="Audio link..."
                                onChange={(event) => setAudioUrl(event.target.value)}
                                value={audioUrl}
                            />
                            <br></br>
                        </div>

                        <div className="form-group create-product-form-item">
                            <label className=''><b>Paperback Link</b></label>
                            <br></br>
                            <input
                                type="text"
                                className="form-control "
                                placeholder="Paperback link..."
                                onChange={(event) => setPaperUrl(event.target.value)}
                                value={paperUrl}
                            />
                            <br></br>
                        </div>

                        <button className="button-no-overlay"
                            type="submit">
                            Add Product
                        </button>

                    </div>

                </form>
            </div>
        </div>
    )
}




export default CreateForm;