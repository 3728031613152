import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios';

import { getAllProducts, getProductById, submitReviewForProduct, createOrder, addProductToOrder, deleteReview, getProductsBySeriesId } from '../api'

import SingleProductSeriesGetter from "./SingleProductSeries";
import SuggestedBook from './SingleProductSeries';





async function submitReview(token, productId, title, description, stars) {
    const result = await submitReviewForProduct(token, productId, title, description, stars);
    // console.log("Review is: ", result)
}

async function addProductToCart(token, productId, quantity) {
    let activeOrderId = localStorage.getItem("ActiveOrderId");
    if (!activeOrderId) {
        const orderID = await createOrder(token);
        // console.log("Setting OrderId", orderID)
        localStorage.setItem("ActiveOrderId", orderID)
        activeOrderId = orderID;
    }

    const addedProduct = await addProductToOrder(token, activeOrderId, productId, quantity);
}

const delay = ms => new Promise(res => setTimeout(res, ms));

const SingleProductView = ({ token, match, history, user }) => {
    const productId = match.params.productId;

    const [singleProduct, setSingleProduct] = useState({});
    const [reviews, setReviews] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [showForm, setShowForm] = useState(false);
    const [showReviews, setShowReviews] = useState(false);
    const [filteredResults, setFilteredResults] = useState([]);
    const [products, setProducts] = useState([]);
    const [suggestedBooks, setSuggestedBooks] = useState([]);
    const [showUpdate, setShowUpdate] = useState(false);
    const [addedToCart, setAddedToCart] = useState(false);

    useEffect(() => {
        fetchSingleProduct(productId)
    }, [productId]);


    useEffect(() => {
        if (singleProduct.series_id) {
            fetchProductsBySeries()
        } else {
            fetchAllProducts();
        }
    }, [singleProduct.series_id])


    const fetchAllProducts = async () => {
        try {
            const list = await getAllProducts();

            const randomIndex = Math.floor(Math.random() * list.data.length);
            const randomIndexToo = Math.floor(Math.random() * list.data.length);

            //get one random index first, then push the book at than index in your data to update suggested books to have that item in it
            //one you add that book, remove that one from your list, so that on next random index, that first one isn't there

            //hardcode setSuggestedBooks -- invoke it and put it in array list.data[1]
        }
        catch (error) {
            console.error("ERROR fetching all products");
            throw error;
        }
    }

    async function fetchSingleProduct() {
        try {
            const result = await getProductById(productId, setSingleProduct);
            setSingleProduct(result)
            if (result.reviews) {
                setReviews(result.reviews);
            }
            return result;
        } catch (error) {
            throw error
        }
    }

    async function fetchProductsBySeries() {
        try {
            const result = await getProductsBySeriesId(singleProduct.series_id);
            setSuggestedBooks(result)
            return result

        } catch (error) {
            throw error
        }
    }

    // console.log("SUGGESTERD BOOKS: ", suggestedBooks)

    return (
        <div className="Single-product-container">
            {singleProduct.name != "Wrath: Special Edition" && <div className="Single-product-content">
                <div className="single-product-head-container">
                    <h1 className={"single-product-head" + (singleProduct.name === "Wrath: Special Edition" ? '-special' : ' ')}><b>{singleProduct.name}</b></h1>
                </div>
                <br></br>
                <div className="single-product-row">
                    <div className="single-product-info-container" >

                        <div className="single-product-image-container">
                            <img src={singleProduct.img_url} alt="Product Cover"
                                className={"single-product-image" + (singleProduct.name === "Wrath: Special Edition" ? '-special' : ' ')}
                            />
                        </div>
                        {/* <div className={"myClass " + (position === index ? 'active' : null)}></div> */}
                        <div className="single-product-specs">
                            <div >
                                {/* <b className="single-product-description">Description:</b> {singleProduct.description} */}
                                {singleProduct.paragraph_one && <p>{singleProduct.paragraph_one}</p>}
                                {singleProduct.paragraph_two && <p>{singleProduct.paragraph_two}</p>}
                                {singleProduct.paragraph_three && <p>{singleProduct.paragraph_three}</p>}
                                {singleProduct.paragraph_four && <p>{singleProduct.paragraph_four}</p>}
                                {singleProduct.paragraph_five && <p>{singleProduct.paragraph_five}</p>}
                                {singleProduct.paragraph_six && <p>{singleProduct.paragraph_six}</p>}
                                {singleProduct.paragraph_seven && <p>{singleProduct.paragraph_seven}</p>}
                                {singleProduct.paragraph_eight && <p>{singleProduct.paragraph_eight}</p>}
                                {singleProduct.paragraph_nine && <p>{singleProduct.paragraph_nine}</p>}
                                {singleProduct.paragraph_ten && <p>{singleProduct.paragraph_ten}</p>}


                            </div>
                            {singleProduct.name === "Wrath: Special Edition" && <img src={'https://i.imgur.com/ipHus47.jpg'} alt="Special Cover Thing"
                                className="single-product-image-special"
                            />}



                            <div >
                                {/* <b >QTY On-Hand:</b> {singleProduct.inventory_qty > 0 ? singleProduct.inventory_qty : "Sold Out" } */}
                            </div>
                            {/* here */}
                        </div>
                    </div>
                    <br></br>
                    {singleProduct.in_series && suggestedBooks.length > 1 ? <div id="product-box" className="series-container">
                        <h4 className="series-head">The {singleProduct.series_name} Series:</h4>
                        <div className='single-product-series-entries'>
                            {suggestedBooks.map((book) => {
                                if (book.vol_num != singleProduct.vol_num) {
                                    return <SuggestedBook bookToDisplay={book} />

                                }
                            })}
                        </div>

                    </div> : null}

                </div>
                <div className="singleProductButtonsContainer">
                    <div className='single-product-buttons-div'>

                        <br></br>
                        <div className='single-product-buttons-goToCart-div'>
                            {addedToCart ? <Link to='/cart'>
                                <button className="single-product-button" >Go to Cart </button>
                            </Link> : null}
                        </div>

                        <div className='single-product-buttons'>
                            {showUpdate ?
                                <div className="signed-copy-order">
                                    <div>
                                        <button onClick={(event) => {
                                            setShowUpdate(false);
                                        }} >X</button>
                                    </div>
                                    {singleProduct.inventory_qty > 0 ? <div>
                                        <label ><b>Quantity</b></label>
                                        <input className="m-3 singleProductQuantity" type="number" id="quantity" value={quantity} min="1" max={singleProduct.inventory_qty}
                                            onChange={(event) => setQuantity(Number(event.target.value))} />
                                        <div >
                                            <b >Price:</b> ${singleProduct.price} + shipping
                                        </div>
                                    </div> : null}
                                    <div className='single-product-buttons-div-plus-addToCart'>
                                        <button className="single-product-button add-to-cart-button" onClick={async (event) => {
                                            event.preventDefault();
                                            addProductToCart(token, singleProduct.id, quantity);
                                            setShowUpdate(false);
                                            setAddedToCart(true);
                                            await delay(1000);
                                            // history.push("/cart");
                                        }}> Add to Cart
                                        </button>
                                        <a href={singleProduct.kindle_link} target="_blank" className="single-product-button">
                                            Kindle Edition
                                        </a>
                                        <a href={singleProduct.audio_link} target="_blank" className="single-product-button">
                                            Audio Edition
                                        </a>

                                        <Link to="/books" className="single-product-button">
                                            Back to Books
                                        </Link>

                                    </div>

                                    <br></br>
                                </div> : null}
                        </div>


                        {!showUpdate ?
                            <div className='single-product-book-buttons'>
                                {singleProduct.inventory_qty > 0 && (!showUpdate ? <button className="single-product-button" onClick={() => setShowUpdate(true)}>Signed Copy </button> : <button className="button" onClick={() => setShowUpdate(false)}>X</button>)}

                                <a href={singleProduct.kindle_link} target="_blank" className="single-product-button">
                                    Kindle Edition
                                </a>
                                <a href={singleProduct.audio_link} target="_blank" className="single-product-button">
                                    Audio Edition
                                </a>

                                <Link to="/books" className="single-product-button">
                                    Back to Books
                                </Link>

                            </div> : null
                        }

                    </div>
                </div>

            </div>}
            {singleProduct.name === "Wrath: Special Edition" && <div className='single-product-special-content'>
                <div className="single-product-head-container">
                    <h1 className="m-3 text-center single-product-head-special"><b>{singleProduct.name}</b></h1>
                </div>
                <br></br>
                <div className="single-product-row">
                    <div className="single-product-info-container" >

                        <div className="single-product-image-container">
                            <img src={singleProduct.img_url} alt="Product Cover"
                                className="single-product-image-special" />
                        </div>
                        <div className="single-product-specs">
                            <div className='special-paragraph-div'>
                                {singleProduct.paragraph_one && <p>{singleProduct.paragraph_one}</p>}
                                {singleProduct.paragraph_two && <p>{singleProduct.paragraph_two}</p>}
                                {singleProduct.paragraph_three && <p>{singleProduct.paragraph_three}</p>}
                                {singleProduct.paragraph_four && <p>{singleProduct.paragraph_four}</p>}
                                {singleProduct.paragraph_five && <p>{singleProduct.paragraph_five}</p>}
                                {singleProduct.paragraph_six && <p>{singleProduct.paragraph_six}</p>}
                                {singleProduct.paragraph_seven && <p>{singleProduct.paragraph_seven}</p>}
                                {singleProduct.paragraph_eight && <p>{singleProduct.paragraph_eight}</p>}
                                {singleProduct.paragraph_nine && <p>{singleProduct.paragraph_nine}</p>}
                                {singleProduct.paragraph_ten && <p>{singleProduct.paragraph_ten}</p>}

                            </div>
                            {singleProduct.name === "Wrath: Special Edition" && <img src={'https://i.imgur.com/ipHus47.jpg'} alt="Special Cover Thing"
                                className="single-product-image-special"
                            />}


                            <div >
                                {/* <b >QTY On-Hand:</b> {singleProduct.inventory_qty > 0 ? singleProduct.inventory_qty : "Sold Out" } */}
                            </div>
                            {/* here */}
                        </div>
                    </div>
                    <br></br>
                    {singleProduct.in_series && suggestedBooks.length > 1 ? <div id="product-box" className="series-container">
                        <h4 className="series-head">The {singleProduct.series_name} Series:</h4>
                        <div className='single-product-series-entries'>
                            {suggestedBooks.map((book) => {
                                if (book.vol_num != singleProduct.vol_num) {
                                    return <SuggestedBook bookToDisplay={book} />

                                }
                            })}
                        </div>

                    </div> : null}
                    {singleProduct.name === 'Wrath' && <div className='the-div-for-the-single-product-conditional-that-doesnt-work'>
                        <h1>Hi guys, it's me, a conditional!</h1>
                    </div>
                    }

                </div>
                <div className="singleProductButtonsContainer">
                    <div className='single-product-buttons-div'>

                        <br></br>
                        <div className='single-product-buttons-goToCart-div'>
                            {addedToCart ? <Link to='/cart'>
                                <button className="single-product-button" >Go to Cart </button>
                            </Link> : null}
                        </div>

                        <div className='single-product-buttons'>
                            {showUpdate ?
                                <div className="signed-copy-order">
                                    <div className='copy-selector'>
                                        <div>
                                            <button onClick={(event) => {
                                                setShowUpdate(false);
                                            }} >X</button>
                                        </div>
                                        {singleProduct.inventory_qty > 0 ? <div>
                                        <label ><b>Quantity</b></label>
                                        <input className="m-3 singleProductQuantity" type="number" id="quantity" value={quantity} min="1" max={singleProduct.inventory_qty}
                                            onChange={(event) => setQuantity(Number(event.target.value))} />
                                        <div >
                                            <b >Price:</b> {singleProduct.price} + shipping
                                        </div>
                                    </div> : null}
                                    </div>

                               
                                    <div className='single-product-buttons-div-plus-addToCart'>
                                        <button className="single-product-button add-to-cart-button" onClick={async (event) => {
                                            event.preventDefault();
                                            addProductToCart(token, singleProduct.id, quantity);
                                            setShowUpdate(false);
                                            setAddedToCart(true);
                                            await delay(1000);
                                            // history.push("/cart");
                                        }}> Add to Cart
                                        </button>
                                        <a href={singleProduct.kindle_link} target="_blank" className="single-product-button">
                                            Kindle Edition
                                        </a>
                                        <a href={singleProduct.audio_link} target="_blank" className="single-product-button">
                                            Audio Edition
                                        </a>

                                        <Link to="/books" className="single-product-button">
                                            Back to Books
                                        </Link>

                                    </div>

                                    <br></br>
                                </div> : null}
                        </div>

                        {!showUpdate ?
                            <div className='single-product-book-buttons'>
                                {singleProduct.inventory_qty > 0 && (!showUpdate ? <button className="single-product-button" onClick={() => setShowUpdate(true)}>Signed Copy </button> : <button className="button" onClick={() => setShowUpdate(false)}>X</button>)}

                                <a href={singleProduct.kindle_link} target="_blank" className="single-product-button">
                                    Kindle Edition
                                </a>
                                <a href={singleProduct.audio_link} target="_blank" className="single-product-button">
                                    Audio Edition
                                </a>

                                <Link to="/books" className="single-product-button">
                                    Back to Books
                                </Link>

                            </div> : null
                        }

                    </div>
                </div>

            </div>
            }
        </div>
    )
}


export default SingleProductView;