import React, { useEffect, useState } from "react";

import { Link } from 'react-router-dom';

// import { history } from "history";


import { Form, Modal, Button } from 'react-bootstrap';

import { getOrderById, closeOrderById, submitOrderById } from "../api";

import { usePayPalScriptReducer, PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js'

// import { FaPencilAlt } from "react-icons/fa'"

async function fetchActiveOrder(setOrder, setUpdatedQtys) {

    const orderId = localStorage.getItem("ActiveOrderId");
    if (!orderId) return {};

    const order = await getOrderById(orderId, setOrder);
    // console.log("THIS IS THE ORDER WITHIN FETCH ACTIVE ORDER ON SHIPPING PAGE: ", order)

}


const Shipping = ({ history, options }) => {

    // const [{ isPending }] = usePayPalScriptReducer();

    const [billingForm, setBillingForm] = useState({
        billingfirstname: "",
        billinglastname: "",
        billingstreet: "",
        billingcity: "",
        billingstate: "",
        billingzip: "",
        billingemail: "",
    });

    const [order, setOrder] = useState({})

    const [review, setReview] = useState(false);
    const [orders, setOrders] = useState({})
    const [updatedQtys, setUpdatedQtys] = useState({});
    const [completeOrder, setCompleteOrder] = useState(false);
    const [showShippingModal, setShowShippingModal] = useState(false);
    const [showThankYou, setShowThankYou] = useState(false);

    const [orderSubmitted, setOrderSubmitted] = useState(false);

    useEffect(() => {
        const billingFromStorage = localStorage.getItem("billingForm") ? JSON.parse(localStorage.getItem("billingForm")) : {
            billingfirstname: "",
            billinglastname: "",
            billingstreet: "",
            billingcity: "",
            billingstate: "",
            billingzip: "",
            billingemail: "",
        }
        setBillingForm(billingFromStorage);
        fetchActiveOrder(setOrder);
    }, [])



    function getTotalItems() {
        let totalItems = order.lineItems.reduce((total, item) => {
            return total + item.quantity

        }, 0);
        return totalItems
    }

    function getTotalPrice() {
        let totalPrice = order.lineItems.reduce((total, item) => {
            return total + item.quantity * item.price


        }, 0);
        // console.log("TOTAL PRICE: ", totalPrice)
        return totalPrice

    }

    // getShippingCost = () => shippingCalculator(totalItems);

    function shippingCalculator(quantity) {
        const shippingPrice = order.lineItems.reduce((total, item) => {
            // console.log("SHIPPING REDUCE ITEM: ", item)
            if (item.series_name === "Special Edition") {
                return total + 10 * item.quantity;
            }
            if (quantity < 2) {
                return total + 7;
            }
            if (quantity == 2) {
                return total + 6;
            }
            if (quantity > 2) {
                return total + 5;
            }

            return total

        }, 0)
        // console.log("SHIPPING CALCULATOR: ", shippingPrice)
        return shippingPrice
        
    }

    function handleFormUpdate(e) {
        e.preventDefault();
        setBillingForm({ ...billingForm, [e.target.name]: e.target.value });
        const billingFromStorage = localStorage.getItem("billingForm") ? JSON.parse(localStorage.getItem("billingForm")) : {
            billingfirstname: "",
            billinglastname: "",
            billingstreet: "",
            billingcity: "",
            billingstate: "",
            billingzip: "",
            billingemail: "",
        }

        billingFromStorage[e.target.name] = e.target.value;

        localStorage.setItem("billingForm", JSON.stringify(billingFromStorage))
        //save billing info as json string in local storage
        //localstorage.setitem, getitem, removeitem
        //want to set an item called billingForm-- look like what state object looks like;
        //add useeffect that as soon as item loads, create billing form in local storage or
        //borders around divs
    }


    function handleClose() {
        setShowShippingModal(false)
    }

    function handleOpen() {
        setShowShippingModal(true)
    }

    function afterSubmit() {
        setShowThankYou(true)
    }

    function closeAfterSubmit() {
        setShowThankYou(false);
        history.push("/");


    }



    // onClick={async (event) => {
    //     event.preventDefault();
    //     addProductToCart(token, singleProduct.id, quantity);
    //     setShowUpdate(false);
    //     setAddedToCart(true);
    //     await delay(1000);
    //     history.push("/cart");
    // }}


    async function submitOrder() {
        // console.log("SUBMIT ORDER: ", order.id)
        const submittedOrder = await submitOrderById(order.id, billingForm);
        localStorage.removeItem("ActiveOrderId");
        setShowThankYou(true);
        console.log("order is submitted")
    }

    const amount = "2";
    const currency = "USD";
    const style = { "layout": "vertical" };

    return (

        <>
            {options["client-id"] ? (


                <PayPalScriptProvider options={options} >
                    {!review ? <Form onSubmit={(event) => {
                        event.preventDefault();
                        setReview(true)
                    }} className='shipping-form-container'
                    >
                        <h2 className='shippingInfoHead'>Shipping Information</h2>
                        <p>Please enter your shipping information and e-mail address below.</p>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>First Name</Form.Label>
                            <div className='shipping-first-name-input'>
                                <Form.Control name="billingfirstname" onChange={handleFormUpdate} type="text" value={billingForm.billingfirstname} placeholder="First Name" required />
                            </div>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control name="billinglastname" onChange={handleFormUpdate} type="text" value={billingForm.billinglastname} placeholder="Last Name" required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Street Address</Form.Label>
                            <Form.Control name="billingstreet" onChange={handleFormUpdate} type="text" value={billingForm.billingstreet} placeholder="Street Address" required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>City</Form.Label>
                            <Form.Control name="billingcity" onChange={handleFormUpdate} type="text" value={billingForm.billingcity} placeholder="City" required />
                        </Form.Group>
                        <div className="d-flex">
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>State</Form.Label>
                                <Form.Control name="billingstate" onChange={handleFormUpdate} type="text" value={billingForm.billingstate} placeholder="State" required />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId={"formBasicPassword"}>
                                <Form.Label>Zip code</Form.Label>
                                <Form.Control name="billingzip" onChange={handleFormUpdate} type="text" value={billingForm.billingzip} placeholder="Zip" required />
                            </Form.Group>
                        </div>
                        <div>
                            <Form.Group className="mb-3" controlId={"formBasicPassword"}>
                                <Form.Label>Email</Form.Label>
                                <Form.Control name="billingemail" onChange={handleFormUpdate} type="text" value={billingForm.billingemail} placeholder="Email" required />
                            </Form.Group>
                        </div>

                        {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Form.Check type="checkbox" label="Check me out" />
        </Form.Group> */}
                        <button className="button" type='submit'  >
                            Review Order
                        </button>

                    </Form> : (
                        <div>
                            {!orderSubmitted ? <div className='shipping-submitted-review-container'>
                                {/* <div className='reviewOrderHeadAndX'>
                                    <h2>Review Your Order</h2>
                                </div> */}
                                <div className='shippingReview'>
                                    <div className='shippingHeadAndEdit'>
                                        <h4 className='shippingReviewsHead'>Shipping Information</h4>
                                        <button onClick={() => setReview(false)}><img src='https://i.imgur.com/RZrKHlD.png' className='editIcon'></img>
                                        </button>
                                    </div>
                                    <div className='shippingReviewGraphs'>
                                        <p>Name: {billingForm.billingfirstname} {billingForm.billinglastname}</p>
                                        <p>Address: {billingForm.billingstreet}</p>
                                        <p>City: {billingForm.billingcity}</p>
                                        <p>State: {billingForm.billingstate}</p>
                                        <p>Zip: {billingForm.billingzip}</p>
                                        <p>Email: {billingForm.billingemail}</p>
                                    </div>




                                </div>
                                <div>
                                    <div className='orderHeadAndEdit'>
                                        <h4 className="shippingReviewsHead">Order Items</h4>
                                        <Link to="/cart" >
                                            <img src='https://i.imgur.com/RZrKHlD.png' className='editIcon'></img>

                                        </Link>
                                        {/* <button className="editButton" onClick={async (event) => {
                                         history.push('/cart');
                                        }}>Edit</button> */}
                                    </div>
                                    <br></br>
                                    <div className="shippingOrderReview">
                                        {(order.lineItems &&
                                            <div id="product-box" className="form-group centered w-100">

                                                <div id="lineItems" className="container2 ">
                                                    {order.lineItems.map((lineItem, index) => {
                                                        let pricePer = lineItem.price;
                                                        let totalLinePrice = lineItem.price * lineItem.quantity;
                                                        let finalTotal = totalLinePrice + totalLinePrice;


                                                        return (

                                                            <div className='reviewOrderContainer'>
                                                                <div className='reviewOrderContent'>
                                                                    <div key={index} className="w-100 p-3 border-dark m-3 bg-body rounded horizGroup">
                                                                        <div className="m-3">
                                                                            <img src={lineItem.img_url}
                                                                                alt="Product Cover"
                                                                                className='cart-image'
                                                                            />
                                                                        </div>
                                                                        <h3 className="w-50 card-title"><b>{lineItem.name}</b></h3>
                                                                        <div className="w-100 horizGroup alignLeft z-index">
                                                                            <div className="cart-order-line-item">
                                                                                <b>Quantity:</b> {lineItem.quantity}
                                                                            </div>
                                                                            <div className="cart-order-line-item">
                                                                                <b>Price Per:</b>  ${pricePer}
                                                                            </div>
                                                                            <div className="cart-order-line-item">
                                                                                <b>Total Price:</b>  ${totalLinePrice.toFixed(2)} + shipping
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>


                                                        )

                                                    })}
                                                    <div className='shippingReviewFinalPrice'>
                                                        <h4 className='shippingReviewsHead'>Total Price</h4>
                                                        <div className='finalPriceTotals'>
                                                            <p className='final-price-totals-line'>Subtotal: ${getTotalPrice().toFixed(2)}</p>
                                                            <p className='final-price-totals-line'>Shipping: ${shippingCalculator(getTotalItems())} <span><i className="fa-solid fa-circle-question" onClick={handleOpen}></i></span></p>
                                                            <h5 className='shippingGrandTotal'><b>Grand Total:</b> ${(getTotalPrice() + shippingCalculator(getTotalItems())).toFixed(2)}</h5>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        )}

                                    </div>
                                </div>
                                <div className='paypalContainerContainer'>
                                    <div className='paypalButtonContainer'>
                                        <div className='shippingMakePaymentHeads'>
                                            <h4 className='shippingCompleteOrderHead'>Complete Your Order</h4>
                                            <p>Make a payment to complete your order</p>
                                        </div>


                                        <PayPalButtons style={{ "layout": "vertical", "display": "block", backgroundColor: 'red', zIndex: '5' }} disabled={false}
                                            forceReRender={[amount, currency, style]}
                                            fundingSource={undefined}
                                            createOrder={(data, actions) => {
                                                return actions.order
                                                    .create({
                                                        purchase_units: [
                                                            {
                                                                amount: {
                                                                    currency_code: "USD",
                                                                    value: `${(getTotalPrice() + shippingCalculator(getTotalItems())).toFixed(2)}`,
                                                                },
                                                            },
                                                        ],
                                                    })
                                                    .then((orderId) => {
                                                        // Your code here after create the order
                                                        return orderId;
                                                    });
                                            }}

                                            onApprove={function (data, actions) {
                                                // submitOrder();
                                                return actions.order.capture().then(function (result) {
                                                    submitOrder()

                                                    // Your code here after capture the order
                                                });
                                            }} />
                                    </div>
                                </div>




                                {/* {order.lineItems ? <button className='button' onClick={submitOrder} >Submit Order</button> :
                            <div className='empty-cart-shipping'>
                                <h2>Your Cart is Empty</h2>
                                <Link to="/books" className="button">
                                    Shop Now
                                </Link>
                              
                            </div>} */}


                            </div> : <h1>THANKS FOR SUBMITTING YOUR ORDER!</h1>}


                        </div>
                    )}
                    <Modal show={showShippingModal} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Shipping Prices</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='shippingExplainer'>
                                <p>One book: $7</p>
                                <p>Two books: $6 per book</p>
                                <p>Three or more books: $5 per book</p>
                                <p>Special Editions: $10 per book</p>
                            </div>



                        </Modal.Body>
                        {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                </Modal.Footer> */}
                    </Modal>

                    <Modal show={showThankYou} onHide={closeAfterSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Order Submitted!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='thankYouModal'>
                                <p>Thank you for your order! It has been submitted.</p>

                            </div>



                        </Modal.Body>
                        {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                </Modal.Footer> */}
                    </Modal>
                </PayPalScriptProvider>

            ) : <div></div>}
        </>


    )
}

export default Shipping