import React, { useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';



const AdminPage = ({ token }) => {
    const history = useHistory();
    useEffect(() => {
        if (!token) {
            history.push('/');
        }
    }, [token, history])

    return (
    <div /*className="form-group centered w-100"*/ className='adminMAIN'>   
        <br />
        <br />
        <div className="admin-button-holder">
            <Link to="/admin-create" className="admin-page-button">CREATE NEW PRODUCT</Link>
            <br />
            <Link to="/admin-products" className="admin-page-button">ALL PRODUCTS</Link>
            <br />
            <Link to="/admin-users" className="admin-page-button">ALL USERS</Link>
            <br />
            <Link to="/admin-orders" className="admin-page-button">ALL ORDERS</Link>
            <br />
            <Link to="/admin-comingsoon" className="admin-page-button">COMING SOON</Link>
            <br />
            <Link to="/admin-addnews" className="admin-page-button">ADD NEWS</Link>
        </div>
        <br />
    </div>)

}



export default AdminPage;