import React from "react";
import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';


import { getContent, updateContent } from '../api';

import { Form } from 'react-bootstrap';

import NewsItem from "./NewsItem";

// export default function Next({ isAdmin }) {

//     const [showUpdate, setShowUpdate] = useState(false);
//     const [whatNext, setWhatNext] = useState({
//         firstGraph: "",
//         secondGraph: "",
//         thirdGraph: ""
//     })

//     async function fetchNextContent() {
//         const next = await getContent();
//         setWhatNext({
//             firstGraph: next.data.next_first_graph,
//             secondGraph: next.data.next_second_graph,
//             thirdGraph: next.data.next_third_graph
//         })
//     }

//     useEffect(() => {
//         fetchNextContent();
//     }, []);

//     function handleNextUpdate(e) {
//         setWhatNext({ ...whatNext, [e.target.name]: e.target.value });

//     }

//     async function handleNextSubmit(event) {
//         event.preventDefault();
//         await updateContent({ next_first_graph: whatNext.firstGraph, next_second_graph: whatNext.secondGraph });
//         setShowUpdate(false);

//     }

//     return (
//         <div className="next-container">
//             <h1 className='next-headline'>Check back for Updates!</h1>
//             <div className="next-info-container">

//                 <div className='next-head-and-info'>
//                     {!showUpdate ? (
//                         <div className="conditional-next">
//                             <div className="next-info">
//                                 <div className='NextNewsItemContainer'>
//                                 </div>



//                             {isAdmin && <button className="about-button" onClick={() => setShowUpdate(true)}>Update </button>}
//                             </div>
//                             <div className="next-info">
//                                 {showUpdate ?
//                                     <input type="text" className="whatNextInput" placeholder="Ella smells" value={whatNext} onChange={(event) => setWhatNext(event.target.value)} />
//                                     : null}
//                             </div>
//                         </div>

//                     ) : (<Form onSubmit={handleNextSubmit}>

//                         <Form.Group className="mb-3" controlId="formBasicPassword">
//                             <Form.Label>First Graph</Form.Label>
//                             <Form.Control name="firstGraph" onChange={handleNextUpdate} type="text" value={whatNext.firstGraph} placeholder="that's what she said" />
//                         </Form.Group>
//                         <Form.Group className="mb-3" controlId="formBasicPassword">
//                             <Form.Label>Second Graph</Form.Label>
//                             <Form.Control name="secondGraph" onChange={handleNextUpdate} type="text" value={whatNext.secondGraph} placeholder="i dunno bro" />
//                         </Form.Group>



//                         <button className="button" type="submit">
//                             Submit
//                         </button>
//                         <button className="button" onClick={() => setShowUpdate(false)}>
//                             X
//                         </button>

//                     </Form>)}
//                 </div>

//             </div>

//         </div>
//     )
// }

export default function Next({ isAdmin }) {

    const [showUpdate, setShowUpdate] = useState(false);
    const [whatNext, setWhatNext] = useState({
        firstGraph: "",
        secondGraph: "",
        thirdGraph: ""
    })

    async function fetchNextContent() {
        const next = await getContent();
        setWhatNext({
            firstGraph: next.data.next_first_graph,
            secondGraph: next.data.next_second_graph,
            thirdGraph: next.data.next_third_graph

        })

    }

    useEffect(() => {
        fetchNextContent();
    }, []);

    function handleNextUpdate(e) {
        setWhatNext({ ...whatNext, [e.target.name]: e.target.value });
        // setSecondGraph({ ...secondGraph, [e.target.name]: e.target.value });

    }

    async function handleNextSubmit(event) {
        event.preventDefault();
        await updateContent({ next_first_graph: whatNext.firstGraph, next_second_graph: whatNext.secondGraph });
        setShowUpdate(false);

    }

    return (
        <div className="next-container">
            {/* <h1 className='next-headline'>Check back for Updates!</h1> */}
            <div className="next-info-container">
                {/* <div className='next-image-div'>
                <img className='next-image' src='https://i.imgur.com/2rgn5X0.jpg'></img>
            </div> */}
                <div className='next-head-and-info'>
                    {!showUpdate ? (
                        <div className="conditional-next">
                            <div className="next-info">
                                <p>{whatNext.firstGraph}</p>
                                <p>{whatNext.secondGraph}</p>
                                <p>{whatNext.thirdGraph}</p>
                                {isAdmin && <button className="about-button" onClick={() => setShowUpdate(true)}>Update </button>}
                            </div>
                            <div className="next-info">
                                {showUpdate ?
                                    <input type="text" className="whatNextInput" placeholder="Ella smells" value={whatNext} onChange={(event) => setWhatNext(event.target.value)} />
                                    : null}
                            </div>
                        </div>

                    ) : (<Form onSubmit={handleNextSubmit}>
                        {/* <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control name="billingfirstname" onChange={handleFormUpdate} type="text" value={billingForm.billingfirstname} placeholder={order.user.billingfirstname} />
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control name="billinglastname" onChange={handleFormUpdate} type="text" value={billingForm.billinglastname} placeholder={order.user.billinglastname} />
                </Form.Group> */}
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>First Graph</Form.Label>
                            <Form.Control name="firstGraph" onChange={handleNextUpdate} type="text" value={whatNext.firstGraph} placeholder="that's what she said" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Second Graph</Form.Label>
                            <Form.Control name="secondGraph" onChange={handleNextUpdate} type="text" value={whatNext.secondGraph} placeholder="i dunno bro" />
                        </Form.Group>


                        {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                          <Form.Check type="checkbox" label="Check me out" />
                        </Form.Group> */}
                        <button className="button" type="submit">
                            Submit
                        </button>
                        <button className="button" onClick={() => setShowUpdate(false)}>
                            X
                        </button>

                    </Form>)}
                </div>





            </div>

        </div>
    )

}
