import React, { useState, useEffect } from 'react';

import { getContent, updateContent } from '../api';

import { Form, Row, Col } from 'react-bootstrap';

export default function About({ isAdmin, setHomeFalse}) {

    //only need aboutfirst and aboutsecond, could make two state variables, first and asecond; on line 15, to get array it would be about.data
    //only care about first and second-- store in state

    const [showUpdate, setShowUpdate] = useState(false);
    const [aboutMe, setAboutMe] = useState({
        firstGraph: "",
        secondGraph: ""
    });


    async function fetchAboutContent() {
        const about = await getContent();
        setAboutMe({
            firstGraph: about.data.about_first_graph,
            secondGraph: about.data.about_second_graph

        })
        // console.log("about.data ", about.data)

    }

    useEffect(() => {
        fetchAboutContent();
    }, []);

    function handleAboutUpdate(e) {
        setAboutMe({ ...aboutMe, [e.target.name]: e.target.value });
        // setSecondGraph({ ...secondGraph, [e.target.name]: e.target.value });

    }

    async function handleAboutSubmit(event) {
        event.preventDefault();
        await updateContent({ about_first_graph: aboutMe.firstGraph, about_second_graph: aboutMe.secondGraph });
        setShowUpdate(false);

    }

    useEffect(() => {
        setHomeFalse()

    }, [])



    return (
        <div className="about-container">
            <div className="about-info-container">
                <h1 className="about-headline">About</h1>
                {!showUpdate ? (
                    <Row className="conditional-about">
                        <Col xs={12} lg={8} className='about-left-column'>
                            <div className="about-info">

                                <p className='about-paragraph1'>Ellis James is a  <i>USA Today</i>  best-selling writer of contemporary m/m romance.</p>  {/*aboutMe.firstGraph*/}
                                <p className='about-paragraph'>{aboutMe.secondGraph}</p>
                                <p className='about-paragraph'>Ellis is represented by Rebecca Friedman at Rebecca Friedman Literary. For business enquiries, including foreign rights, please contact: rebecca@rfliterary.com</p>
                                <p className='about-paragraph'>For personal inquiries, please email ellisjamesbooks@gmail.com</p>

                                {isAdmin && <button className="about-button" onClick={() => setShowUpdate(true)}>Update </button>}
                            </div>
                            <div className="about-info">
                                {showUpdate ?
                                    <input type="text" className="aboutMeInput" placeholder="Ella smells" value={aboutMe} onChange={(event) => setAboutMe(event.target.value)} />
                                    : null}
                            </div>
                        </Col>
                        <Col xs={12} lg={4} className='about-right-column'>
                            <div className="about-image-container">
                                <img className="about-image" src="https://i.imgur.com/5EcmNlw.jpg"></img>
                            </div>
                        </Col>

                    </Row>


                ) : (<Form className="aboutMeInput" onSubmit={handleAboutSubmit}>
                    {/* <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control name="billingfirstname" onChange={handleFormUpdate} type="text" value={billingForm.billingfirstname} placeholder={order.user.billingfirstname} />
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control name="billinglastname" onChange={handleFormUpdate} type="text" value={billingForm.billinglastname} placeholder={order.user.billinglastname} />
                    </Form.Group> */}
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>First Graph</Form.Label>
                        <Form.Control name="firstGraph" onChange={handleAboutUpdate} type="text" value={aboutMe.firstGraph} placeholder="that's what she said" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Second Graph</Form.Label>
                        <Form.Control name="secondGraph" onChange={handleAboutUpdate} type="text" value={aboutMe.secondGraph} placeholder="i dunno bro" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Image url</Form.Label>
                        <Form.Control name="secondGraph" onChange={handleAboutUpdate} type="text" placeholder="put the link here" />
                    </Form.Group>


                    {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                              <Form.Check type="checkbox" label="Check me out" />
                            </Form.Group> */}
                    <button className="button" type="submit">
                        Submit
                    </button>
                    <button className="button" onClick={() => setShowUpdate(false)}>
                        X
                    </button>

                </Form>)}



            </div>

        </div>
    )

}