import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getProductById, updateProduct } from '../api';


async function fetchSingleProduct(productId, setSingleProduct) {
    try {
        const result = await getProductById(productId, setSingleProduct)
        console.log("SINGLE PRODUCT RESULT", result)
        setSingleProduct(result)
        console.log("Result ", result);
        return result;
    } catch (error) {
        throw error
    }
}

const AdminUpdate = ({ history, match }) => {

    const productId = match.params.productId;
    const [newName, setNewName] = useState("");
    const [newDescription, setNewDescription] = useState("");
    const [newPrice, setNewPrice] = useState("");
    const [newInvQty, setNewInvQty] = useState("");
    const [newImgUrl, setNewImgUrl] = useState("");
    const [singleProduct, setSingleProduct] = useState('');

    const [newParagraphOne, setNewParagraphOne] = useState('');
    const [newParagraphTwo, setNewParagraphTwo] = useState('');
    const [newParagraphThree, setNewParagraphThree] = useState('');
    const [newParagraphFour, setNewParagraphFour] = useState('');
    const [newParagraphFive, setNewParagraphFive] = useState('');
    const [newParagraphSix, setNewParagraphSix] = useState('');
    const [newParagraphSeven, setNewParagraphSeven] = useState('');
    const [newParagraphEight, setNewParagraphEight] = useState('');
    const [newParagraphNine, setNewParagraphNine] = useState('');
    const [newParagraphTen, setNewParagraphTen] = useState('');

    const [newKindleUrl, setNewKindleUrl] = useState('');
    const [newAudioUrl, setNewAudioUrl] = useState('');
    const [newPaperbackUrl, setNewPaperbackUrl] = useState('');


    useEffect(() => {
        fetchSingleProduct(productId, setSingleProduct)
    }, [productId, setSingleProduct])

    useEffect(() => {
        setNewName(singleProduct.name);
        setNewDescription(singleProduct.description);
        setNewPrice(singleProduct.price);
        setNewInvQty(singleProduct.inventory_qty);
        setNewImgUrl(singleProduct.img_url);
    }, [singleProduct])

    return (
        <div className="admin-update-product-first-div">
            <Link to="/admin" className='button'>
                GO BACK
            </Link>
            <br />
            <br />
            <div className='centered'>
                <div className="admin-update-product-head">
                    <h3>Update Product</h3>
                </div>
            </div>
            <div className=''>
                <form
                    className=" "
                    onSubmit={async (event) => {
                        event.preventDefault();
                        try {
                            await updateProduct(productId, newName, newDescription, newPrice, newInvQty, newImgUrl);

                            history.push("/admin");
                        }
                        catch (error) {
                            console.error('ERROR with submission for updating the product!!!');
                            throw error;
                        }
                    }}>
                    <div id="product-box" className='container update-product-input-container'>
                        <div className="form-group ">
                            <label>Product Name</label>
                            <br></br>
                            <input
                                type="text"
                                value={newName || ""}
                                className="form-control"
                                placeholder={singleProduct.name}
                                onChange={(event) => setNewName(event.target.value)}
                            />
                            <br></br>
                        </div>

                        <div className="form-group">
                            <label>Description</label>
                            <br></br>
                            <input
                                type="text"
                                value={newDescription || ""}
                                className="form-control"
                                placeholder={singleProduct.description}
                                onChange={(event) => setNewDescription(event.target.value)}
                            />
                            <br></br>
                        </div>

                        {singleProduct.paragraph_one && <div className="form-group">
                            <label>Paragraph 1</label>
                            <br></br>
                            <input
                                type="text"
                                value={newParagraphOne || ""}
                                className="form-control"
                                placeholder={singleProduct.paragraph_one}
                                onChange={(event) => setNewParagraphOne(event.target.value)}
                            />
                            <br></br>
                        </div>}

                        {singleProduct.paragraph_two && <div className="form-group">
                            <label>Paragraph 2</label>
                            <br></br>
                            <input
                                type="text"
                                value={newParagraphTwo || ""}
                                className="form-control"
                                placeholder={singleProduct.paragraph_two}
                                onChange={(event) => setNewParagraphTwo(event.target.value)}
                            />
                            <br></br>
                        </div>}

                        {singleProduct.paragraph_three && <div className="form-group">
                            <label>Paragraph 3</label>
                            <br></br>
                            <input
                                type="text"
                                value={newParagraphThree || ""}
                                className="form-control"
                                placeholder={singleProduct.paragraph_three}
                                onChange={(event) => setNewParagraphThree(event.target.value)}
                            />
                            <br></br>
                        </div>}

                        {singleProduct.paragraph_four && <div className="form-group">
                            <label>Paragraph 4</label>
                            <br></br>
                            <input
                                type="text"
                                value={newParagraphFour || ""}
                                className="form-control"
                                placeholder={singleProduct.paragraph_four}
                                onChange={(event) => setNewParagraphFour(event.target.value)}
                            />
                            <br></br>
                        </div>}

                        {singleProduct.paragraph_five && <div className="form-group">
                            <label>Paragraph 5</label>
                            <br></br>
                            <input
                                type="text"
                                value={newParagraphFive || ""}
                                className="form-control"
                                placeholder={singleProduct.paragraph_five}
                                onChange={(event) => setNewParagraphFive(event.target.value)}
                            />
                            <br></br>
                        </div>}

                        {singleProduct.paragraph_six && <div className="form-group">
                            <label>Paragraph 6</label>
                            <br></br>
                            <input
                                type="text"
                                value={newParagraphSix || ""}
                                className="form-control"
                                placeholder={singleProduct.paragraph_six}
                                onChange={(event) => setNewParagraphSix(event.target.value)}
                            />
                            <br></br>
                        </div>}

                        {singleProduct.paragraph_seven && <div className="form-group">
                            <label>Paragraph 7</label>
                            <br></br>
                            <input
                                type="text"
                                value={newParagraphSeven || ""}
                                className="form-control"
                                placeholder={singleProduct.paragraph_seven}
                                onChange={(event) => setNewParagraphSeven(event.target.value)}
                            />
                            <br></br>
                        </div>}

                        {singleProduct.paragraph_eight && <div className="form-group">
                            <label>Paragraph 8</label>
                            <br></br>
                            <input
                                type="text"
                                value={newParagraphEight || ""}
                                className="form-control"
                                placeholder={singleProduct.paragraph_eight}
                                onChange={(event) => setNewParagraphEight(event.target.value)}
                            />
                            <br></br>
                        </div>}

                        {singleProduct.paragraph_nine && <div className="form-group">
                            <label>Paragraph 9</label>
                            <br></br>
                            <input
                                type="text"
                                value={newParagraphNine || ""}
                                className="form-control"
                                placeholder={singleProduct.paragraph_nine}
                                onChange={(event) => setNewParagraphNine(event.target.value)}
                            />
                            <br></br>
                        </div>}

                        {singleProduct.paragraph_ten && <div className="form-group">
                            <label>Paragraph 10</label>
                            <br></br>
                            <input
                                type="text"
                                value={newParagraphTen || ""}
                                className="form-control"
                                placeholder={singleProduct.paragraph_ten}
                                onChange={(event) => setNewParagraphTen(event.target.value)}
                            />
                            <br></br>
                        </div>}

                        <div className="form-group">
                            <label>Price</label>
                            <br></br>
                            <input
                                type="text"
                                value={newPrice || ""}
                                className="form-control"
                                placeholder={singleProduct.price}
                                onChange={(event) => setNewPrice(event.target.value)}
                            />
                            <br></br>
                        </div>

                        <div className="form-group">
                            <label>Quantity In Inventory</label>
                            <br></br>
                            <input
                                type="text"
                                value={newInvQty || ""}
                                className="form-control"
                                placeholder={singleProduct.inventory_qty}
                                onChange={(event) => setNewInvQty(event.target.value)}
                            />
                            <br></br>
                        </div>

                        <div className="form-group">
                            <label>Image URL</label>
                            <br></br>
                            <input
                                type="text"
                                value={newImgUrl || ""}
                                className="form-control"
                                placeholder={singleProduct.img_url}
                                onChange={(event) => setNewImgUrl(event.target.value)}
                            />
                            <br></br>
                        </div>

                        <div className="form-group">
                            <label>Kindle URL</label>
                            <br></br>
                            <input
                                type="text"
                                value={newKindleUrl || ""}
                                className="form-control"
                                placeholder={singleProduct.kindle_link}
                                onChange={(event) => setNewKindleUrl(event.target.value)}
                            />
                            <br></br>
                        </div>

                        <div className="form-group">
                            <label>Audiobook URL</label>
                            <br></br>
                            <input
                                type="text"
                                value={newAudioUrl || ""}
                                className="form-control"
                                placeholder={singleProduct.audio_link}
                                onChange={(event) => setNewAudioUrl(event.target.value)}
                            />
                            <br></br>
                        </div>

                        {singleProduct.print_link && <div className="form-group">
                            <label>Paperback URL</label>
                            <br></br>
                            <input
                                type="text"
                                value={newPaperbackUrl || ""}
                                className="form-control"
                                placeholder={singleProduct.print_link}
                                onChange={(event) => setNewPaperbackUrl(event.target.value)}
                            />
                            <br></br>
                        </div>}
                    </div>
                    <div className='form-group centered'>
                        <button className="button"
                            type="submit">
                            Update Product
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AdminUpdate;