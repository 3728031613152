import React, { useEffect, useState, useReducer } from 'react';
import { Link } from 'react-router-dom';
import { getAllProducts } from "../api";

import testStroke from "../images/testStroke.jpg"

// import ellaJamesLogo from '../images/ellaJamesLogo.jpg';

const reducer = (state = { index: 0, used: [0] }, action) => {
    switch (action.type) {
        case "randomizeIndex":
            if (state.used.length === action.products.length) {
                return {
                    index: 0,
                    used: [0]
                }
            }
            let randomIdx = Math.floor(Math.random() * action.products.length);
            while (state.used.includes(randomIdx)) {
                randomIdx = Math.floor(Math.random() * action.products.length);
            }
            return {
                ...state,
                index: randomIdx,
                used: [...state.used, randomIdx]
            }
        default:
            return state
    }
}


export default function HomeDisplayCoverGenerator({ products }) {

    const [state, dispatch] = useReducer(reducer, { index: 0, used: [0] });
    // let productsToDisplay = products.filter((product) => {
    //         return product.id != 2 && product.id != 3 && product.id != 4 && product.id != 5
    //     })

    const updatedProducts = products.filter((item)=> item.id != 5);


    useEffect(() => {
        // let indexArray = [];
        // console.log("USE EFFECT: ", updatedProducts, products)
        const interval = setInterval(() => {
            dispatch({
                type: "randomizeIndex",
                products: updatedProducts
            })
        }, 3000);
        return () => clearInterval(interval)

    }, [])


    const bookToDisplay = updatedProducts[state.index]

    return (
        <div className="home-display-cover">


            <div className="head-text">
                {/* <div className="head-image">
                    <img className='home-book-cover-stroke' src={require('../images/testStroke.png')} alt="Freedom Blog" />
                </div> */}
                <div class='text-on-image'>
                   <h1 className='home-display-cover-head'>Available Now!</h1>
                </div>
            </div>

            {/* <h1 className='home-display-cover-head'>Available Now!</h1> */}
            <div className="shadow rounded no-padding "> {/*removed card*/}
                <div className="card no-padding home-image-container">
                    <Link to={`/books/${bookToDisplay.id}`}>
                        <img className="home-rotating-image" src={bookToDisplay.img_url} alt={bookToDisplay.title} />
                        {/* <div className="home-image-overlay">
                                <div className="homeImageOverlayText ">{bookToDisplay.quote}</div>
                            </div> */}

                    </Link>
                </div>

            </div>
        </div>
    )

}
