import React, { useEffect, useState } from "react";

import { Form, Modal, Button } from 'react-bootstrap';

import Accordion from 'react-bootstrap/Accordion';

import { Link } from 'react-router-dom';

import dayjs from "dayjs";

import { getNews, updateNews } from '../api';


const NewsItem = ({ isAdmin }) => {

    const [headline, setHeadline] = useState(false);
    const [graph1, setGraph1] = useState('');
    const [graph2, setGraph2] = useState('');
    const [graph3, setGraph3] = useState('');
    const [date, setDate] = useState('');

    const [picBottom, setPicBottom] = useState(false);
    const [picTop, setPicTop] = useState(false);
    const [picRight, setPicRight] = useState(false);

    const [goodreads, setGoodReads] = useState(false);

    const [news, setNews] = useState({
        newsHeadline: "",
        firstGraph: "",
        secondGraph: "",
        thirdGraph: "",
        fourthGraph: "",
        fifthGraph: "",
        sixthGraph: "",
        seventhGraph: "",
        linkDescription: "",
        image_url: "",
        news_date: "",
    });

    const [update, setUpdate] = useState(false);

    const [submitted, setSubmitted] = useState(false);

    //okay, but how to i turn this into a date that stays

    useEffect(() => {
        const newsFromStorage = localStorage.getItem("news") ? JSON.parse(localStorage.getItem("news")) : {
            newsHeadline: "",
            firstGraph: "",
            secondGraph: "",
            thirdGraph: "",
            fourthGraph: "",
            fifthGraph: "",
            sixthGraph: "",
            seventhGraph: "",
            linkDescription: "",
            image_url: "",
            news_date: "",
        }
        setNews(newsFromStorage);
        setSubmitted(false);
    }, [])


    function handleFormUpdate(e) {
        e.preventDefault();
        setNews({ ...news, [e.target.name]: e.target.value });
        const newsFromStorage = localStorage.getItem("news") ? JSON.parse(localStorage.getItem("news")) : {
            newsHeadline: "",
            firstGraph: "",
            secondGraph: "",
            thirdGraph: "",
            fourthGraph: "",
            fifthGraph: "",
            sixthGraph: "",
            seventhGraph: "",
            linkDescription: "",
            image_url: "",
            news_date: ""
        }

        newsFromStorage[e.target.name] = e.target.value;

        localStorage.setItem("newsForm", JSON.stringify(newsFromStorage))

    }


    async function fetchNews() {
        const news = await getNews();
        setNews({
            newsHeadline: news.data.news_headline,
            firstGraph: news.data.news_first_graph,
            secondGraph: news.data.news_second_graph,
            thirdGraph: news.data.news_third_graph,
            fourthGraph: news.data.news_fourth_graph,
            fifthGraph: news.data.news_fifth_graph,
            sixthGraph: news.data.news_sixth_graph,
            seventhGraph: news.data.news_seventh_graph,
            linkDescription: news.data.link_description,
            image_url: news.data.news_image_url,
            news_date: news.data.news_date,

        })
    }

    useEffect(() => {
        fetchNews();
    }, [submitted]);

    async function handleSubmit(e) {
        e.preventDefault();
        await updateNews({
            news_headline: news.newsHeadline,
            news_first_graph: news.firstGraph,
            news_second_graph: news.secondGraph,
            news_third_graph: news.thirdGraph,
            news_fourth_graph: news.fourthGraph,
            news_fifth_graph: news.fifthGraph,
            news_sixth_graph: news.sixthGraph,
            news_seventh_graph: news.seventhGraph,
            link_description: news.linkDescription,
            news_image_url: news.image_url
        });
        setSubmitted(true);
        setUpdate(false);
    }

    // console.log("ALL THE NEWS THAT FITS: ", news)

    return (
        <div className="news-item-container">
            <div className='news-item-product-card'>
                <div className='news-item-date-div'>
                    <p className='news-item-date'><b>{news.news_date}</b></p>
                </div>
                <div className='news-item-headline-div'>
                    <h1 className='news-item-headline'>{news.newsHeadline}</h1>
                </div>
                <div className={`news-item-copy-div${picBottom ? "-bottom" : ""}`}>
                    <div className='news-item-paragrah-div'>
                        <p>{news.firstGraph}</p>
                        <p>{news.secondGraph}</p>
                        <p>{news.thirdGraph}</p>
                        <p>{news.fourthGraph}{news.newsHeadline === "All is Bright" ? <a href={'https://www.amazon.com/Worthy-Pride-Anthology-Ashley-James-ebook/dp/B0BNNKFPVN/ref=sr_1_1?crid=1JVLX5MH4BHBM&keywords=worthy+a+pride+anthology&qid=1671329521&sprefix=Worthy%3A+A+Pri%2Caps%2C141&sr=8-1'}> You'll also find one of my short stories in an anthology publishing in early 2023.</a> : null}</p>
                        <p>{news.fifthGraph}</p>
                        <p>{news.sixthGraph}</p>
                        <p>{news.seventhGraph}</p>
                    </div>

                    {news.image_url && <div className='news-item-image-div'>
                        <img src={news.image_url} className="news-item-image" />
                    </div>}
                </div>
                {news.linkDescription && <div className='button'>{news.linkDescription}</div>}
                <div className='news-item-update-div'>
                    {(isAdmin && !update) && <button className="button" onClick={(event) => {
                        event.preventDefault();
                        setUpdate(true);
                    }}>Update</button>}
                    {(isAdmin && update) && <button className='button' onClick={(event) => {
                        event.preventDefault();
                        setUpdate(false);
                    }}>X</button>}

                </div>
                {update && <div className='news-item-admin-update'>
                    <div>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Headline</Form.Label>
                                <div className='shipping-first-name-input'>
                                    <Form.Control name="newsHeadline" onChange={handleFormUpdate} type="text" value={news.newsHeadline} placeholder="Headline" required />
                                </div>
                                <Form.Label>First Graph</Form.Label>
                                <Form.Control name="firstGraph" onChange={handleFormUpdate} type="text" value={news.firstGraph} placeholder="First Graph" required />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Second Graph</Form.Label>
                                <Form.Control name="secondGraph" onChange={handleFormUpdate} type="text" value={news.secondGraph} placeholder="Second Graph" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Third Graph</Form.Label>
                                <Form.Control name="thirdGraph" onChange={handleFormUpdate} type="text" value={news.thirdGraph} placeholder="Third Graph" />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Fourth Graph</Form.Label>
                                <Form.Control name="fourthGraph" onChange={handleFormUpdate} type="text" value={news.fourthGraph} placeholder="Fourth Graph" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId={"formBasicPassword"}>
                                <Form.Label>Fifth Graph</Form.Label>
                                <Form.Control name="fifthGraph" onChange={handleFormUpdate} type="text" value={news.fifthGraph} placeholder="Fifth Graph" />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId={"formBasicPassword"}>
                                <Form.Label>Sixth Graph</Form.Label>
                                <Form.Control name="fifthGraph" onChange={handleFormUpdate} type="text" value={news.sixthGraph} placeholder="sixth Graph" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId={"formBasicPassword"}>
                                <Form.Label>Seventh Graph</Form.Label>
                                <Form.Control name="seventhGraph" onChange={handleFormUpdate} type="text" value={news.seventhGraph} placeholder="Seventh Graph" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId={"formBasicPassword"}>
                                <Form.Label>Link Description</Form.Label>
                                <Form.Control name="linkDescription" onChange={handleFormUpdate} type="text" value={news.linkDescription} placeholder="Link Description" />
                            </Form.Group>
                            <div>
                                <Form.Group className="mb-3" controlId={"formBasicPassword"}>
                                    <Form.Label>Image Url</Form.Label>
                                    <Form.Control name="image_url" onChange={handleFormUpdate} type="text" value={news.image_url} placeholder="Image Url" />
                                </Form.Group>

                            </div>
                            <button type='submit' className='button'>Submit</button>
                        </Form>
                    </div>
                </div>}

            </div>
        </div>

    )
}

export default NewsItem;