import React from "react";
import { useState, useEffect } from "react";
import { getOrderById, removeLineItemByID, closeOrderById, addProductToOrder, updateLineItem } from "../api";
import { Link } from 'react-router-dom';
import { UpdateBilling } from './updateBilling';
import { Form } from 'react-bootstrap';





const LineItem = ({ lineItem, token, updateRecieved, setUpdateRecieved, itemRemoved, setItemRemoved, removeLineItem }) => {
    const [updatedQty, setUpdatedQty] = useState(lineItem.quantity);

    // const [completeOrder, setCompleteOrder] = useState(false);
    const [updateQuantity, setUpdateQuantity] = useState(false)

    let pricePer = lineItem.price

    let quantity = lineItem.quantity;

    let shipping;

    let totalLinePrice = (lineItem.price * lineItem.quantity) + shipping

    let finalTotal = totalLinePrice + totalLinePrice;



    // useEffect(() => {
    //     shippingCalculator(quantity)
    //   });


    // async function removeLineItem(lineItemId, setOrder, setUpdatedQtys, itemRemoved, setItemRemoved) {
    //     const deletedItem = await removeLineItemByID(lineItemId);
    //     // await fetchActiveOrder(setOrder, setUpdatedQtys);
    // }



    return (


        <div className="p-3 border-dark m-3 rounded cart-line-item" >
            <div className='cart-line-item-image-and-title'>
                <div className="m-3">
                    <img src={lineItem.img_url}
                        alt="Product Cover"
                        className='cart-image'
                    />
                </div>
                <h3 className="card-title"><b>{lineItem.name}</b></h3>
            </div>


            <div className="w-100 horizGroup alignLeft">
                <div className='cart-lineitem-everything-but-the-remove'>
                    {!updateQuantity ? <div className="cart-order-line-item">
                        <div className='cart-quantity-update'>
                            <b>Quantity:</b> {lineItem.quantity}
                            <button className='quantityPadding' onClick={() => setUpdateQuantity(true)}><img src='https://i.imgur.com/G3wCFJu.png' className='editIconCart'></img>
                            </button>
                        </div>
                    </div> :
                        <div className="m-5 form-group list-group-item-text">
                            <label>Update Quantity</label>
                            <input className="m-3" type="number" id="quantity" value={updatedQty}
                                min="1" max="100"
                                onChange={({ target: { value } }) => {
                                    setUpdatedQty(value);
                                }} />
                            <button className="updateCartButton" onClick={async (event) => {
                                event.preventDefault();
                                try {
                                    const orderId = Number(localStorage.getItem("ActiveOrderId"));
                                    await updateLineItem(Number(updatedQty), lineItem.id, token);
                                    setUpdateRecieved(updateRecieved + 1)
                                    // await fetchActiveOrder(setOrder, setUpdatedQtys);
                                    setUpdateQuantity(false);

                                } catch (error) {
                                    console.log("UPDATE QUANTITIES ERROR, ", error)
                                }

                            }}> Update
                            </button>
                        </div>

                    }
                    <div className="cart-order-line-item">
                        <b>Price Per:</b>  ${lineItem.price}
                    </div>
                    <div className="cart-order-line-item">
                        <b>Total Price:</b>  ${lineItem.price * lineItem.quantity} + shipping
                    </div>

                </div>

                {/* <div className="m-5 form-group list-group-item-text">
                                                <label>Update Quantity</label>
                                                <input className="m-3" type="number" id="quantity"
                                                    min="1" max="100"
                                                    onChange={({ target: { value } }) => {
                                                        let newQuantities = updatedQtys;
                                                        newQuantities[lineItem.id] = value;
                                                        setUpdatedQtys(newQuantities);
                                                    }} />
                                                <button className="updateCartButton" onClick={async (event) => {
                                                    event.preventDefault();
                                                }}> Update
                                                </button>
                                            </div> */}
                <div className="form-group list-group-item-text">
                    <button className="button" onClick={async (event) => {
                        // event.preventDefault();
                        removeLineItem(lineItem.id);
                        console.log("HEY YOU HIT REMOVE")

                    }}> Remove
                    </button>
                </div>
            </div>
        </div>)

}


export default LineItem;