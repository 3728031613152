import React from "react";
import { useState } from "react";
import { loginUser } from "../api";
import { Link } from "react-router-dom";

// This component takes us to the login page for a registered user.
// On success a token will be given and stored in localStorage.
// User must confirm their password in order to log in successfully.
const Login = ({ setToken,
    setUser,
    setIsAdmin,
    history,
    match }) => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loginError, setLoginError] = useState(false);



    return (
        <div className="d-flex w-100 justify-content-center">
            <form className="d-flex flex-column w-75"
                onSubmit={async (event) => {
                    event.preventDefault();
                    const orderId = localStorage.getItem("ActiveOrderId");
                    const loginResult = await loginUser(email, password, setToken,
                        setUser,
                        setIsAdmin, orderId
                    );
                    if (loginResult === "Invalid credentials. Try again") {
                        setLoginError(true);
                    } else {
                        history.push("/admin")
                    }
                }
                }>
                <br />
                <br />
                <br />
                <div className='login-container'>
                    <div className='form-group centered'>
                        <div className="login-header">
                            <h2>Login</h2>
                            {/* <img src="https://emb.gov.ph/wp-content/uploads/2017/02/login-button-png-18028.png"
                            style={{width: 300, height: 50}}
                            alt="Login"
                            className="" /> */}
                        </div>
                    </div>
                    <div classname='login-input-container'>
                        <div className="form-group mb-3 login-input">
                            <label><h5><b >Email</b></h5></label>
                            <input
                                onChange={({ target: { value } }) => setEmail(value)}
                                type="text"
                                className="form-control"
                                placeholder="Enter your Email"
                                required
                            />
                        </div>

                        <div className="form-group mb-3 login-input">
                            <label><h5><b >Password</b></h5></label>
                            <input
                                onChange={({ target: { value } }) => setPassword(value)}
                                type="password"
                                className="form-control"
                                placeholder="Enter password"
                                required
                            />
                        </div>
                        <button className="button"
                            type="submit">
                            Login
                        </button>
                    </div>
                    {/* <div className="login-register">
                        {(match.url === "/register" ?
                            <Link to="/login">Already a user? Login Here! </Link>
                            :
                            <Link className="login-register" to="/register"><b >Not a User? Register Here!</b></Link>
                        )}
                    </div> */}

                </div>



                {loginError && <p>Invalid credentials. Try again or create an account</p>}
            </form>

        </div>

    )
}

export { Login };
