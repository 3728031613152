import React from 'react';
import { useState, useEffect } from 'react';
import { createNews } from '../api';
import { Link } from 'react-router-dom';

import axios from 'axios';
import ProductDescriptionEdit from './ProductDescriptionEdit';

import { BASE_URL } from '../constants';

import UploadAssist from './UploadAssist';


const CreateNews = ({ }) => {

    const [mainHead, setMainHead] = useState("");
    const [headline, setHeadline] = useState("");
    const [imgUrl, setImgUrl] = useState("");
    const [kindleUrl, setKindleUrl] = useState("");
    const [graph, setGraph] = useState("");

    const [theDate, setTheDate] = useState('');

    const [allGraphs, setAllGraphs] = useState([]);
    const [helpClick, setHelpClick] = useState(false);

    


    // useEffect(() => {
    //     axios.get(`${BASE_URL}/series`).then((response) => {
    //         setSeries(response.data)
    //     })
    // }, [])

    function addNewGraph() {
        setAllGraphs([...allGraphs, graph]);
        setGraph('');
    }

    function updateGraph(originalText, newText) {
        const updatedGraphs = allGraphs.map((item, index, allGraphs) => {
            if (originalText === item) {
                item = newText
            }
            return item
        })
        setAllGraphs(updatedGraphs)
    }

    function removeParagraph(originalText) {
        const updatedGraphs = allGraphs.filter((item) => {
            return item != originalText
        })
        setAllGraphs(updatedGraphs)
    }

    // useEffect(() => {
    //     const current = new Date();
    //     const date = `${current.getMonth() + 1}/${current.getDate()}/${current.getFullYear()}`;
    //     setTheDate(date);
    //     console.log("This is the date: ", date);

    // });


    return (
        <div className="form-group w-100 addNews-container">
            <br />
            <div className='createProductTopButtons'>
                <div className='goBackButtonDiv'>
                    <Link to="/admin" className='createProductGoBackButton'>
                        GO BACK
                    </Link>
                </div>
                {/* <div className='updateHelp'>
                    {!helpClick ? <button onClick={() => setHelpClick(true)} className='createProductGoBackButton'>Help</button>
                    : <button onClick={() => setHelpClick(false)} className='createProductGoBackButton'>X</button>}

                </div> */}
            </div>


            {helpClick && <div className='uploadAssistCreateProductDiv'>
                <UploadAssist />
            </div>}

            <div>
                <form
                    className="centered m-3 w-100"
                    onSubmit={async (event) => {
                        event.preventDefault();
                        console.log("PRODUCT SUBMITTED!")
                        try {
                            await createNews(headline, allGraphs, imgUrl, kindleUrl);
                            // history.push("/books")
                        }
                        catch (error) {
                            console.error('ERROR with submission for creating a new product!!! 🤦‍♂️');
                            throw error;
                        }
                    }}>

                    <div className="form-group w-50">
                        <label className=''><b>Headline</b></label>
                        <br></br>
                        <input
                            type="text"
                            className="form-control "
                            placeholder="Product name..."
                            required
                            onChange={(event) => setHeadline(event.target.value)}
                            value={headline}
                        />
                        <br></br>
                    </div>

                    <div className="form-group w-50 paragraphs-div">
                        <label className='create-product-paragraphs-head'><b>Paragraphs</b></label>
                        <br></br>
                        <textarea
                            className="form-control "
                            placeholder="Add a paragraph to your summary"
                            onChange={(event) => setGraph(event.target.value)}
                            value={graph}
                        />
                        {allGraphs.length < 10 ? <button className="add-graph-button" onClick={addNewGraph}>Add Graph</button> : <h4 className='paragraph-limit-head'>You've reached your paragraph limit</h4>}
                        <br></br>
                        <div className='added-graphs-div'>
                            <div className="border">
                                <h4 className="graphs-head">Graphs</h4>
                                {allGraphs.map((graph, index) => {
                                    return <ProductDescriptionEdit graph={graph} key={index} className='editedGraphs' updateGraph={updateGraph} removeParagraph={removeParagraph} />

                                })}
                            </div>
                        </div>
                    </div>


                    <div className="form-group w-50">
                        <label className=''><b>Image URL</b></label>
                        <br></br>
                        <input
                            type="text"
                            className="form-control "
                            placeholder="Image URL..."
                            onChange={(event) => setImgUrl(event.target.value)}
                            value={imgUrl}
                        />
                        <br></br>
                    </div>

                    <div className="form-group w-50">
                        <label className=''><b>Kindle Link</b></label>
                        <br></br>
                        <input
                            type="text"
                            className="form-control "
                            placeholder="Kindle link..."
                            onChange={(event) => setKindleUrl(event.target.value)}
                            value={kindleUrl}
                        />
                        <br></br>
                    </div>

                    <button className="button-no-overlay"
                        type="submit">
                        Add News
                    </button>
                </form>
            </div>
        </div>
        
    )

}

export default CreateNews;