import React from "react";
import { useState, useEffect } from "react";
import { getAllProducts } from "../api";
import { Link } from 'react-router-dom';
import { Card, Row, Col } from 'react-bootstrap';

import Search from "./Search";

import SeriesRow from "./SeriesRow";


// This component renders the All Products page (maps out all the products in the database)
const AllProducts = ({setHomeFalse}) => {
    const [products, setProducts] = useState([]);
    const [filteredResults, setFilteredResults] = useState([]);

    const sinful = products.filter((product) => product.series_name === 'Sinful Secrets');

    const knees = products.filter((product) => product.series_name === 'On My Knees');

    const special = products.filter((product) => product.series_name === 'Special Editions');


    const fetchAllProducts = async () => {
        try {
            const list = await getAllProducts();
            setProducts(list.data);
            setFilteredResults(list.data);
            // console.log('LIST DOT DATE: ', list.data)
        }
        catch (error) {
            console.error("ERROR fetching all products");
            throw error;
        }
    }



    useEffect(() => {
        fetchAllProducts();
    }, []);

    useEffect(() => {
        setHomeFalse()

    }, [])

    return (
        <div className='new-big-all-products-container'>
            <div className='all-products-head'>
                <h1 className='all-products-h1'>Books</h1>
            </div>
            <div className="all-products-page">
        
                <br></br>
                <SeriesRow seriesBooks={sinful} seriesTitle="Sinful Secrets"/>
                <br></br>
                <SeriesRow seriesBooks={knees} seriesTitle="On My Knees"/>
                <br></br>
                <SeriesRow seriesBooks={special} seriesTitle="Special Editions"/>

            </div>
        </div>

    )
}

export default AllProducts;