import React, { useState, useEffect } from 'react';

import { Form } from 'react-bootstrap';

import { getContent, updateContent } from '../api';

import dayjs from 'dayjs';

export default function Contact({isAdmin}) {
    const [showUpdate, setShowUpdate] = useState(false);
    const [contact, setContact] = useState({
        firstGraph: "",
        secondGraph: ""
    });

    async function fetchContactContent() {
        const contact = await getContent();
        setContact({
            firstGraph: contact.data.contact_first_graph,
            secondGraph: contact.data.contact_second_graph

        })
        // console.log("contact.data.contact_first_graph", contact.data.contact_first_graph)

    }

    useEffect(() => {
        fetchContactContent();
    }, [])

    function handleContactUpdate(e) {
        setContact({...contact, [e.target.name]: e.target.value });
    }

    async function handleContactSubmit(event){
        event.preventDefault();
        await updateContent({contact_first_graph: contact.firstGraph, contact_second_graph: contact.secondGraph});
        setShowUpdate(false);

    }


    return (
        <div className="contact-container">
            <div className="contact-info-container">
                {/* <h2 src="path/to/dayjs/dayjs.min.js">{dayjs().format()}</h2> */}
                <h2 className='contactHead'>Contact</h2>
                {!showUpdate ? (
                    <div className="conditional-contact">
                        <div className="contact-info">
                            <p>{contact.firstGraph}</p>
                            <p>{contact.secondGraph}</p>
                            {isAdmin && <button className="button" onClick={() => setShowUpdate(true)}>Update </button>}
                        </div>
                    </div>

                ) : (<Form onSubmit={handleContactSubmit}>
                    {/* <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control name="billingfirstname" onChange={handleFormUpdate} type="text" value={billingForm.billingfirstname} placeholder={order.user.billingfirstname} />
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control name="billinglastname" onChange={handleFormUpdate} type="text" value={billingForm.billinglastname} placeholder={order.user.billinglastname} />
                    </Form.Group> */}
                    <Form.Group className="contact-input" controlId="formBasicPassword">
                        <Form.Label>First Graph</Form.Label>
                        <Form.Control name="firstGraph" onChange={handleContactUpdate} type="text" value={contact.firstGraph} placeholder="that's what she said" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Second Graph</Form.Label>
                        <Form.Control name="secondGraph" onChange={handleContactUpdate} type="text" value={contact.secondGraph} placeholder="i dunno bro" />
                    </Form.Group>
                   

                    {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                              <Form.Check type="checkbox" label="Check me out" />
                            </Form.Group> */}
                    <button className="button" type="submit">
                        Submit
                    </button>
                    <button className="button" onClick={() => setShowUpdate(false)}>
                        X
                    </button>

                </Form>)}


            </div>

        </div>
    )

}