import React, { useState, useEffect } from "react";

import { BASE_URL } from "../constants";

export default function Newsletter({ headers }) {

    const [subscribed, setSubscribed] = useState(false);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");

    return (
        <div className="newsletter-container">
            <h4>Subscribe to Ella's Newsletter!</h4>
            <div className="subscription-container">
                <form onSubmit={async (event) => {
                    event.preventDefault();

                    let options = {
                        method: "POST",
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                          },
                        body: JSON.stringify({
                                firstName: firstName,
                                lastName: lastName,
                                email: email
                        }
                        )
                    }

                    fetch(`${BASE_URL}/newsletter`, options)

                        .then(response => response.json())
                        .then((result) => {
                            setSubscribed(true);
                            alert("THANKS FOR SUBSCRIBING");
                            setFirstName("");
                            setLastName("");
                            setEmail("")

                        })
                        .catch(console.error);

                }}>

                    <input
                        className="FirstNameInput"
                        type="text"
                        placeholder="First Name"
                        onChange={(event) => {
                            setFirstName(event.target.value);
                        }}
                        value={firstName}
                    />

                    <input
                        className="LastNameInput"
                        type="text"
                        placeholder="Last Name"
                        onChange={(event) => {
                            setLastName(event.target.value);
                        }}
                        value={lastName}
                    />

                    <input
                        type="text"
                        placeholder="Email"
                        onChange={(event) => {
                            setEmail(event.target.value);
                        }}
                        value={email}
                    />

                    <button type="submit" className="button" disabled={subscribed}>{!subscribed ? "Submit" : "Submitted!"}</button>
                </form>

            </div>

        </div>

    )
}