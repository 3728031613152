import React, { useEffect, useState } from "react";
//import { useHistory } from "react-router";
import { getAllProducts } from "../api"
import { Link } from 'react-router-dom';

import booksCard from '../images/booksCard.png';
// import whatNext from '../images/whatNext.jpg';

import HomeDisplayCoverGenerator from "./HomeDisplayBookGetter";

import ellisjamesnebulacopy from '../images/ellisjamesnebulacopy.png'


// import ImageTester from "./testingSHIT";

const Home = ({ setHomeTrue }) => {
    /*const history = useHistory();*/

    const [products, setProducts] = useState([]);
    // const [bookToDisplay, setBookToDisplay] = useState("") 

    // const bookCovers = ['https://i.imgur.com/2ervAXS.jpg',
    //     'https://i.imgur.com/sLem0ft.jpg',
    //     'https://i.imgur.com/fwScInk.jpg',
    //     'https://i.imgur.com/RbdMVuJ.jpg',] 

    const fetchAllProducts = async () => {
        try {
            const list = await getAllProducts();
            setProducts(list.data);
        }
        catch (error) {
            console.error("ERROR fetching all products");
            throw error;
        }
    }


        // useEffect(() => {
        //     const intervalId = setInterval(() => {
        //         setBookToDisplay(bookCovers[Math.floor(Math.random() * items.length)]);
        //     }, 5000)

        //     return () => clearInterval(intervalId);
        // }, [])


useEffect(() => {
    fetchAllProducts();
}, []);

// console.log(products);

useEffect(() => {
    setHomeTrue()

}, [])

useEffect(() => {

})


return (
    <div className="page">
        <br></br>
        <div className="home-content-container">


            {products.length > 0 && <HomeDisplayCoverGenerator products={products} />}


        </div>
    </div>
)
}

export default Home;


// import React, { useEffect, useState } from "react";
// //import { useHistory } from "react-router";
// import { getAllProducts } from "../api"
// import { Link } from 'react-router-dom';

// import booksCard from '../images/booksCard.png';
// import whatNext from '../images/whatNext.jpg';

// import HomeDisplayCoverGenerator from "./HomeDisplayBookGetter";

// // import ImageTester from "./testingSHIT";

// const Home = () => {
//     /*const history = useHistory();*/

//     const [products, setProducts] = useState([]);

//     const fetchAllProducts = async () => {
//         try {
//             const list = await getAllProducts();
//             setProducts(list.data);
//         }
//         catch (error) {
//             console.error("ERROR fetching all products");
//             throw error;
//         }
//     }

//     useEffect(() => {
//         fetchAllProducts();
//     }, []);

//     console.log(products);


//     return (
//         <div className="page">
//             <br></br>
//             <div className="w-90">
//                 <div className="home-cards-container">
//                     <div className='top-home-row'>
// <div className="card m-3 shadow rounded home-product-card">
//     <div className="home">
//         <Link to={'/products'}>
//             <img src={booksCard} alt="Product Cover" className="homeImage" />
//             <div className="overlay">
//             </div>
//             <div className="overlayText">Books</div>

//         </Link>
//     </div>
// </div>

// <div className="card m-3 shadow rounded home-product-card">
//     <div className="home">
//         <Link to={'/about'}>
//             <img src={aboutMe} alt="Product Cover" className="homeImage" />
//             <div className="overlay">
//             </div>
//             <div className="overlayText">About</div>

//         </Link>
//     </div>
// </div>

//                     </div>

//                     <div className='bottom-home-row'>
//                         <div className="card m-3 shadow rounded home-product-card last-home-card">  {/* REMOVED p-3 from all these bitches; that's what gave it some padding */}

//                             <div className="home">
//                                 <Link to={'/next'}>
//                                     <img src={'https://i.imgur.com/NIqXfpz.jpg'} alt="Product Cover" className="homeImage" />
//                                     <div className="overlay">
//                                     </div>
//                                     <div className="overlayText">What's Next</div>

//                                 </Link>
//                             </div>
//                         </div>

//                         <div className="card m-3 shadow rounded home-product-card last-home-card">

//                             <div className="home">
//                                 <Link to={'/next'}>
//                                     <img src={whatNext} alt="Product Cover" className="homeImage" />
//                                     <div className="overlay">
//                                     </div>
//                                     <div className="overlayText">What's Next</div>

//                                 </Link>
//                             </div>
//                         </div>
//                     </div>


//                 </div>



//                 {products.length > 0 && <HomeDisplayCoverGenerator products={products} />}
//                 {/* {products.length > 0 && <ImageTester bookToDisplay={products[Math.floor(Math.random() * products.length)]}/>} */}



//             </div>
//         </div>
//     )
// }

// export default Home;