import React from "react";
import { useState, useEffect } from "react";
import { deleteOrderById, getAllOrders, closeOrderById } from "../api";
import { Link } from "react-router-dom";

import OrderItem from "./OrderItem";




const AdminOrders = ({ history }) => {

    const [orders, setOrders] = useState([]);

    const [openOrders, showOpenOrders] = useState(false)
    const [closedOrders, showClosedOrders] = useState(false);


    const fetchAllOrders = async () => {
        try {
            const list = await getAllOrders();
            setOrders(list.data);
        }
        catch (error) {
            console.error("ERROR fetching all orders - useEffect in AdminOrders");
            throw error;
        }
    }

    useEffect(() => {
        fetchAllOrders();
    }, []);

    const handleDelete = async (order) => {
        try {
            const response = await deleteOrderById(order.id);
            console.log(response);
            fetchAllOrders()
            // history.push("/admin")

        }
        catch (err) {
            console.error("trouble deleting order", err)
        }
    }

    const handleClose = async (order) => {
        try {
            const response = await closeOrderById(order.id);
            console.log(response);
            fetchAllOrders();

        } catch (err) {
            console.error("trouble closing order", err)
        }
    }



    return (
        <div id="product-box" className="form-group w-100">
            <br />
            <Link to="/admin" className='button'>
                GO BACK
            </Link>
            <br />
            <div className="centered">
                <br />
                <div>
                    <h1 className='admin-orders-main-head'>Orders</h1>
                </div>
            </div>
            <br />
            <div id="product" className="container centered">
                <h3 className='admin-orders-open-order-head'><b>Open Orders</b></h3>
                {orders.map((order, index) => {

                    if (order.order_status === "Open") {
                        return (
                            <OrderItem order={order} handleDelete={handleDelete} handleClose={handleClose} />
                        )

                    }

                    // return (
                    //     <div className='main-item-div-admin-orders'>
                    //         <div className="order-status-open-div">
                    //             {order.order_status === "Open" && <OrderItem order={order} handleDelete={handleDelete} handleClose={handleClose} />}
                    //         </div>

                    //     </div>

                    // )
                })}
                <h3 className='admin-orders-closed-order-head' onClick={() => showClosedOrders(!closedOrders)} ><b>Closed Orders</b></h3>

                {closedOrders && orders.map((order, index) => {

                    console.log("ORDER STATUS: ", order.order_status)

                    if (order.order_status === "Closed") {
                        return (
                            <OrderItem order={order} handleDelete={handleDelete} handleClose={handleClose} />
                        )
                    }
                })}
            </div>
        </div>)



}

export default AdminOrders;