import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAllProducts } from "../api";

export default function SuggestedBook({bookToDisplay}) {
 
    return (
        <div className="single-product-series-getter">
            <div className="shadow rounded no-padding "> {/*removed card*/}
                
                <div className="card no-padding series-image-container">
             
                   <Link to={`/books/${bookToDisplay.id}`}>
                        <img className="series-image" src={bookToDisplay.img_url} alt={bookToDisplay.title} />
                  
                  
                     </Link>
                </div>
                 
            </div>
        </div>

    )

}