import React, { useEffect, useState } from "react";
import { Navbar, Container, Nav, Form, Button, FormControl } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { saveOrderToUser } from "../api";
import ellaLogoTransparent from '../images/ellaLogoTransparent.png';

import ellisjamesnebulacopy from '../images/ellisjamesnebulacopy.png'


const Footer = ({ newsletter, handleChange, handleNewsletterSubmit }) => {

    return (
        <div className="footer">
            <div className="footer-container">
                <div className="top-row footerRow">
                    <div className="footerColumn">
                        <form onSubmit={handleNewsletterSubmit} className="m-3">
                            <label htmlFor="messageTextArea" className="form-label footer-subscription-head"><h5 className='subscribe-footer-head'>SUBSCRIBE TO ELLIS' NEWSLETTER</h5></label>
                            <h6 className='footerSubscriptionSubhead'>Get access to exclusive content and giveaways!</h6>
                            <div className="form-group footer-subscription-form">
                                {/* <label><b>First Name</b></label> */}
                                <input onChange={handleChange} value={newsletter.firstName} name="firstName" type="text" className="form-control footer-news-input" placeholder="First Name" required />
                                {/* <label><b>Last Name</b></label> */}
                                <input onChange={handleChange} value={newsletter.lastName} name="lastName" type="text" className="form-control footer-news-input" placeholder="Last Name" required />
                                {/* <label><b>Email</b></label> */}
                                <input onChange={handleChange} value={newsletter.email} name="email" type="text" className="form-control footer-news-input" placeholder="Email" required />
                                {/* <br></br> */}
                                <button type='submit' className="button" >Submit</button>
                            </div>
                            {/* <textarea className="form-control" id="newsletterEmail" value={newsletterEmail} placeholder="email" onChange={({ target: { value } }) => {
                                setNewsletterEmail(value);
                            }}></textarea> */}

                            {/* <br></br> */}
                        </form>
                    </div>
                    <div className="vl"></div>

                    <div className="footerColumn">
                        <Link to={'/books/5'}>
                            <img className="ellis-footer-image" src={'https://i.imgur.com/5hEBZQ4.png'} />
                        </Link>
                    </div>
                    <div className="vl"></div>
                    <div className="sexyFooterColumn">
                        <h3 className='footerConnectHead'>CONNECT</h3>
                        <div className="connect-icons ">
                            {/* <div className="head-image"> */}
                            {/* <img className='footer-connect-stroke' src={require('../images/ellisjamesnebulacopy.png')} alt="Connect Stroke" /> */}
                            {/* </div> */}
                            <div className="text-on-image-footer">
                                {/* <div style={{position:"relative"}}> */}
                                <img className='footer-connect-stroke' src={require('../images/ellisjamesnebulacopy2.png')} alt="Connect Stroke" />
                                {/* </div> */}
                                {/* <Link to='https://www.facebook.com/ellajamesauthorpage' target="_blank"> */}
                                <div className='socialIcons'>
                                    <a href='https://www.facebook.com/profile.php?id=100083316363651' target="_blank"><img src='https://i.imgur.com/ox9CxXK.png' className='footer-connect-icon' alt="Facebook logo" /> </a>
                                    {/* </Link> */}
                                    <a href='https://www.instagram.com/authorellisjames/' target="_blank"><img src='https://i.imgur.com/cp6y2mZ.png' className='footer-connect-icon' alt="Facebook logo" /> </a>

                                    {/* <Link to={'https://www.instagram.com/authorellisjames/'}>
                                        <img src='https://i.imgur.com/cp6y2mZ.png' className='footer-connect-icon' alt="Instagram logo" />
                                    </Link> */}
                                    {/* <a href='https://www.twitter.com' target="_blank">
                                    <img src='https://i.imgur.com/XWyi2hf.png' className='footer-connect-icon' alt="Twitter logo" />

                                    </a> */}

                                    {/* <Link to={'twitter.com'}>
                                    </Link> */}

                                </div>

                            </div>
                        </div>
                        <div className='eliteDiv'>
                            <a href='https://www.facebook.com/groups/524161769326670' target="_blank"><h3 className='eliteLink'>Join Ellis' Cockatarians!</h3> </a>
                        </div>
                        {/* <div className="bottom-row">
                            <p className="copyright">
                                &copy;(2022) Ellis James | All rights reserved | <a href='https://www.ucanhaswebsite.com/' target="_blank" >Designed by U Can Has Website  </a>
                            </p>
                        </div> */}
                        {/* <br></br> */}
                        {/* <div className='sexy-boys'>
                            Click HERE for Ella's steamy m/m romance
                        </div> */}
                    </div>
                </div>
                {/* <div className="hl"> </div> */}


            </div>

        </div>


    )
}

export default Footer;