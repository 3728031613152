import React from "react";
import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';


import { getContent, updateContent } from '../api';

import { Form } from 'react-bootstrap';

import NewsItem from "./NewsItem";

export default function News({ isAdmin, setHomeFalse }) {

    useEffect(() => {
        setHomeFalse()

    }, [])
   

    return (

        <div className="news-container">
            <NewsItem isAdmin={isAdmin}/>
        </div>


    )
}