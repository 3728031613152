import React from 'react';
import { useState, useEffect } from 'react';
import { createComingSoon } from '../api';
import { Link } from 'react-router-dom';

import ProductDescriptionEdit from './ProductDescriptionEdit';
import axios from 'axios';

import { BASE_URL } from '../constants';

import UploadAssist from './UploadAssist';


const CreateSoon = ({ history }) => {

    const [mainHead, setMainHead] = useState("");
    const [headline, setHeadline] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [kindleUrl, setKindleUrl] = useState("");
    const [goodReadsUrl, setGoodReadsUrl] = useState("");
    const [graph, setGraph] = useState("");
    const [allGraphs, setAllGraphs] = useState([]);
    const [helpClick, setHelpClick] = useState(false);

    const [showGraphs, setShowGraphs] = useState(false);


    function addNewGraph() {
        setAllGraphs([...allGraphs, graph]);
        setGraph('');
        console.log("ADD GRAPH")
    }

    function updateGraph(originalText, newText) {
        const updatedGraphs = allGraphs.map((item, index, allGraphs) => {
            if (originalText === item) {
                item = newText
            }
            return item
        })
        setAllGraphs(updatedGraphs)
    }

    function removeParagraph(originalText) {
        const updatedGraphs = allGraphs.filter((item) => {
            return item != originalText
        })
        setAllGraphs(updatedGraphs)
    }


    return (
        <div className="form-group w-100">
            <br />
            <div className='createProductTopButtons'>
                <div className='goBackButtonDiv'>
                    <Link to="/admin" className='createProductGoBackButton'>
                        GO BACK
                    </Link>
                </div>
                {/* <div className='updateHelp'>
                    {!helpClick ? <button onClick={() => setHelpClick(true)} className='createProductGoBackButton'>Help</button>
                    : <button onClick={() => setHelpClick(false)} className='createProductGoBackButton'>X</button>}

                </div> */}
            </div>


            {helpClick && <div className='uploadAssistCreateProductDiv'>
                <UploadAssist />
            </div>}

            <div>
                <form
                    className="centered m-3 w-100 formClassName"
                    onSubmit={async (event) => {
                        event.preventDefault();
                        console.log("PRODUCT SUBMITTED!")
                        try {
                            await createComingSoon(mainHead, headline, allGraphs, imageUrl, kindleUrl, goodReadsUrl);
                            // history.push("/books")
                        }
                        catch (error) {
                            console.error('ERROR with submission for creating a new product!!! 🤦‍♂️');
                            throw error;
                        }
                    }}>

                    <div className="form-group w-50 z-index">
                        <label className=''><b>Coming Soon Headline</b></label>
                        <br></br>
                        <input
                            type="text"
                            className="form-control "
                            placeholder="Headline..."
                            required
                            onChange={(event) => setHeadline(event.target.value)}
                            value={headline}
                        />
                        <br></br>
                    </div>


                    <div className="form-group w-50 paragraphs-div z-index">
                        <label className='create-product-paragraphs-head'><b>Paragraphs</b></label>
                        <br></br>
                        <textarea
                            className="form-control "
                            placeholder="Add a paragraph to your summary"
                            onChange={(event) => setGraph(event.target.value)}
                            value={graph}
                        />
                        {allGraphs.length < 10 ? <p className="add-graph-button" /*onClick={addNewGraph}*/ onClick={() => {
                            addNewGraph();
                            setShowGraphs(true);
                        }} >Add Graph</p> : <h4 className='paragraph-limit-head'>You've reached your paragraph limit</h4>}
                        <br></br>
                        {showGraphs && <div className='added-graphs-div'>
                            <div className="border">
                                <h4 className="graphs-head">Graphs</h4>
                                {allGraphs.map((graph, index) => {
                                    return <ProductDescriptionEdit graph={graph} key={index} className='editedGraphs' updateGraph={updateGraph} removeParagraph={removeParagraph} />

                                })}
                            </div>

                        </div>}

                    </div>


                    <div className="form-group w-50 z-index">
                        <label className=''><b>Image URL</b></label>
                        <br></br>
                        <input
                            type="text"
                            className="form-control "
                            placeholder="Image URL..."
                            onChange={(event) => setImageUrl(event.target.value)}
                            value={imageUrl}
                        />
                        <br></br>
                    </div>

                    <div className="form-group w-50 z-index">
                        <label className=''><b>Kindle Link</b></label>
                        <br></br>
                        <input
                            type="text"
                            className="form-control "
                            placeholder="Kindle link..."
                            onChange={(event) => setKindleUrl(event.target.value)}
                            value={kindleUrl}
                        />
                        <br></br>
                    </div>

                    <div className="form-group w-50 z-index">
                        <label className=''><b>Goodreads Link</b></label>
                        <br></br>
                        <input
                            type="text"
                            className="form-control "
                            placeholder="Audio link..."
                            onChange={(event) => setGoodReadsUrl(event.target.value)}
                            value={goodReadsUrl}
                        />
                        <br></br>
                    </div>

                    <button className="button-no-overlay z-index"
                        type="submit">
                        Submit
                    </button>
                </form>
            </div>
        </div>
    )
}




export default CreateSoon;