import React, { useState } from 'react';


function ProductDescriptionEdit({ graph, updateGraph, removeParagraph }) {
    const [editMode, setEditMode] = useState(false);
    const [graphText, setGraphText] = useState(graph); 

    function handleEditClick() {
        updateGraph(graph, graphText);
        setEditMode(false);
    }

    // let clickCount = 0;
    // function clickCountUpdate(){
    //     clickCount = clickCount + 1;
    //     console.log("THE CLICK COUNT: ", clickCount)

    // }

    return (
        <div>
            {!editMode ? (
                <div className='createProductGraphAndButton'>
                    <div className='createProductGraphDiv'>
                        <p className='createProductGraph'>{graph}</p>
                    </div>
                    <div className='createProductGraphEditButtonDiv'>
                        <button className='createProductGraphEditButton' onClick={() => setEditMode(true)}>Edit</button>
                        <button className='deleteProductDescriptionButton' onClick={() => removeParagraph(graph)}>Delete</button>
                    </div>

                </div>
            ) : (
                <div className='editCreateProductGraphAndButton'>
                    <div className='editCreateGraphInputDiv'>
                        <textarea value={graphText} className='createProductEditInput' onChange={(e) => setGraphText(e.target.value)} />
                    </div>
                    <div className='updateEditedGraphDiv'>
                        <button className='updateEditedCreateProductButton' onClick={handleEditClick} >Update</button>
                    </div>
                </div>
            )}
        </div>
    )

}

export default ProductDescriptionEdit