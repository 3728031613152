import React from 'react';

import { Navbar, Container, Nav } from 'react-bootstrap';

import { NavLink, Link } from 'react-router-dom';

import { saveOrderToUser } from "../api";


// import ellaJamesLogo from '../images/ellaJamesLogo.jpg';

// import ellisjamessqcopy from '../images/ellisjamessqcopy.png'
// import ellisjamessqcopy from '../images/ellisjamessqcopy2.png'
import ellisjamessqcopy from '../images/ellisjamessqcopy3.png'


const NewTitleNav = ({
    isAdmin,
    token,
    setToken,
    setUser,
    setIsAdmin,
    isHome
}) => {
    return (

        <div className='newTitleNavContainer'>
            <div className="ellis-james-title">
                <Link to="/">
                    <img className="ellis-james-title-image" src={ellisjamessqcopy} />
                </Link>
            </div>
            <Navbar bg="red" expand="lg" className='theMainNavbarDiv' collapseOnSelect>
                <Container style={{ justifyContent: "center" }}>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="boot-nav-container">
                        <Nav className="boot-nav-link-container">
                            {/* {!isHome && <Nav.Link as={NavLink} eventKey='1' to="/" className="boot-nav-link"><b>Home</b></Nav.Link>} */}
                            <Nav.Link as={NavLink} eventKey='1' to="/books" className="boot-nav-link">Books</Nav.Link>
                            <Nav.Link as={NavLink} eventKey='2' to="/about" className="boot-nav-link">About</Nav.Link>
                            <Nav.Link as={NavLink} eventKey='3' to="/news" className="boot-nav-link">Updates</Nav.Link>

                            {/* <Nav.Link as={NavLink} eventKey='4' to="/contact" className="boot-nav-link">Contact</Nav.Link> */}
                            <Nav.Link as={NavLink} eventKey='4' to="/cart" className="boot-nav-link">Cart</Nav.Link>
                            {!isHome && <Nav.Link as={NavLink} eventKey='5' to="/" className="boot-nav-link"><b>Home</b></Nav.Link>}

                            {isAdmin ? <Nav.Link as={NavLink} eventKey='6' to="/admin" className="boot-nav-link">Admin</Nav.Link> : null}
                            {token ?
                                <Nav.Link as={NavLink} eventKey='7' to="/login" onClick={(event) => {
                                    const activeOrderId = localStorage.getItem("ActiveOrderId")
                                    if (activeOrderId) {
                                        saveOrderToUser(token, activeOrderId);
                                    }
                                    localStorage.removeItem("token");
                                    localStorage.removeItem("user");
                                    localStorage.removeItem("isAdmin");
                                    localStorage.removeItem("ActiveOrderId");
                                    setToken("");
                                    setIsAdmin(false);
                                    setUser(null);
                                }}
                                    className="boot-nav-link">Logout</Nav.Link> : null

                            }

                        </Nav>
                    </Navbar.Collapse>
                </Container>
               
            </Navbar>

        </div>

    )
}

export default NewTitleNav;