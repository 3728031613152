import React, { useState, useEffect } from "react";

const UploadAssist = () => {

    const [nameHelp, setNameHelp] = useState(false);
    const [quoteHelp, setQuoteHelp] = useState(false);
    const [graphsHelp, setGraphsHelp] = useState(false);
    const [priceHelp, setPriceHelp] = useState(false);
    const [qtyHelp, setQtyHelp] = useState(false);
    const [whatBlurb, setWhatBlurb] = useState(false);
    const [moreGraphs, setMoreGraphs] = useState(false);



    return (
        <div className='uploadAssistContainer'>
            <div className='uploadAssistContent'>
                <div className='uploadAssistHeads'>
                    <h1 >Welcome to Upload Assist</h1>
                    <h4>What do you need help with?</h4>
                </div>

                <div className='helpGraphsContainer'>
                    <div className="helpGraphContainer">
                        <b><h5 onClick={() => setNameHelp(true)} className='helpOption'>Name</h5></b>

                    </div>


                    <b><h5 onClick={() => setQuoteHelp(true)} className='helpOption'>Quote</h5></b>

                    <b><h5 className='helpOption'>Paragrahs</h5></b>
                    {graphsHelp &&
                        <div>
                            <h5>This is where you upload your blurb. Do it one graph at a time.</h5>
                            <p>What is a blurb</p>
                            {whatBlurb && <p>Seriously Susan?</p>}
                            <p>I want more than 10 graphs!</p>
                            {moreGraphs && <p>Really Ralphie? Really?</p>}

                        </div>}

                    <h5 className='helpOption'>Price</h5>
                    {priceHelp && <p>This is where you put the price of a single copy of your book. Remember, you are inputting a price for a <b>signed</b> copy</p>}

                    <h5 className='helpOption'>Inventory Quantity</h5>
                    {qtyHelp && <p>How many copies of your book do you have right now? At this very moment?</p>}

                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                </div>
                <div className="helpGraphsResponses">
                    {nameHelp && <p>Type the name of the book</p>};
                    {quoteHelp && <p>Choose a quote from the book. It can be your favorite quote. It can be a quote that you feel like your readers will like the most. It can even be a quote that isn't really in the book that you wrote for promotional materialws (I know how y'all do).</p>}


                </div>
            </div>

        </div>
    )

}

export default UploadAssist