import React, {useState} from 'react';

import { deleteOrderById, getAllOrders, closeOrderById } from "../api";


const OrderItem = ({order, handleDelete, handleClose}) => {

    const [showDetails, setShowDetails] = useState(false)

    const [showUser, setShowUser] = useState(false);

    return (
        <div key={order.id} className="p-3 border-dark rounded centered admin-orders-card">
            <div className="form-group list-group-item card-title centered">
                <h3 className="card-title">
                    <b>{"ORDER #" + order.id}</b>
                </h3>
            </div>
            <div className="horizGroup">
                <div className="w-75">
                    {/* <div className="form-group">
                                    <b className="">USER ID #</b>{order.userId}
                                </div> */}
                    <div className="form-group">
                        <b className="">ORDER STATUS:</b> {order.order_status}
                    </div>
                    {!showDetails ? <button className='button' onClick={() => setShowDetails(true)}>See Details</button> :
                        <button className='button' onClick={() => setShowDetails(false)}>X</button>}
                    {showDetails && <div className="shippingOrderReview">
                        {(order.lineItems &&
                            <div id="product-box" className="form-group centered w-100">

                                <div id="lineItems" className="container2 ">
                                    {order.lineItems.map((lineItem, index) => {
                                        let pricePer = lineItem.price;
                                        let totalLinePrice = lineItem.price * lineItem.quantity;
                                        let finalTotal = totalLinePrice + totalLinePrice;


                                        return (

                                            <div className='reviewOrderContainer'>
                                                <div className='reviewOrderContent'>
                                                    <div key={index} className="card w-100 p-3 border-dark m-3 bg-body rounded ">
                                                        <div className='horizGroup'>
                                                            <div className="m-3">
                                                                <img src={lineItem.img_url}
                                                                    alt="Product Cover"
                                                                    className='cart-image'
                                                                />
                                                            </div>
                                                            <h3 className="w-50 card-title"><b>{lineItem.name}</b></h3>
                                                            <div className="w-100 horizGroup alignLeft">
                                                                <div className="cart-order-line-item">
                                                                    <b>Quantity:</b> {lineItem.quantity}
                                                                </div>
                                                                <div className="cart-order-line-item">
                                                                    <b>Price Per:</b>  ${pricePer}
                                                                </div>
                                                                <div className="cart-order-line-item">
                                                                    <b>Total Price:</b>  ${totalLinePrice.toFixed(2)}
                                                                </div>
                                                                {!showUser ? <button className='button' onClick={() => {
                                                                    setShowUser(true)
                                                                }}>Show User</button> :
                                                                    <button className='button' onClick={() => {
                                                                        setShowUser(false)
                                                                    }}>Hide User</button>
                                                                }
                                                            </div>
                                                        </div>
                                                        {showUser && <div className='shippingAdminOrderUserContainter'>
                                                            <div className='userContainer'>
                                                                <div className='adminOrderUserInfo'>
                                                                    <div className='adminOrderUserName'>
                                                                        <b className='adminOrderInfoItem'>First Name:</b> <p className='adminOrderInfoItem'>{order.billingfirstname}</p>
                                                                        <b className='adminOrderInfoItem'>Last Name:</b> <p className='adminOrderInfoItem'>{order.billinglastname}</p>
                                                                    </div>
                                                                    <div className='adminOrderAddressInfo'>
                                                                        <b className='adminOrderInfoItem'>Street:</b> {order.billingstreet}
                                                                        <b className='adminOrderInfoItem'>City:</b> {order.billingcity}
                                                                        <b className='adminOrderInfoItem'>State:</b> {order.billingstate}
                                                                        <b className='adminOrderInfoItem'>Zip Code:</b> {order.billingzip}
                                                                    </div>
                                                                    <div className='adminOrderUserEmail'>
                                                                        <b className='adminOrderInfoItem'>Email:</b> {order.billingemail}

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>}


                                                    </div>

                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )}
                        <div className="admin-orders-completed-delete-div">
                            <button className='admin-orders-button'
                                onClick={ () => handleClose(order) }

                            >Close</button>
                            <button
                                onClick={ () => handleDelete(order)}
                                type="submit" className="admin-orders-delete-button">DELETE</button>
                        </div>

                    </div>}
                    <br />


                </div>
            </div>
            <br></br>
        </div>
    )
}

export default OrderItem;