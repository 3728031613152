import React from "react";
import { useState, useEffect } from "react";
import { getOrderById, removeLineItemByID, closeOrderById, addProductToOrder } from "../api";
import { Link } from 'react-router-dom';
import { UpdateBilling } from './updateBilling';
import { Form } from 'react-bootstrap';
import LineItem from './LineItem'


async function fetchActiveOrder(setOrder) {

    const orderId = localStorage.getItem("ActiveOrderId");
    if (!orderId) return {};

    const order = await getOrderById(orderId, setOrder);

    if (order && order.lineItems) {
        let newQtys = [];
        order.lineItems.forEach(lineItem => {
            newQtys[lineItem.id] = lineItem.quantity;
        });
    }
}

async function removeLineItem(lineItemId, setOrder) {
    const deletedItem = await removeLineItemByID(lineItemId);
    await fetchActiveOrder(setOrder);
}


const delay = ms => new Promise(res => setTimeout(res, ms));

const Cart = ({ token, setToken, history, setHomeFalse }) => {
    const [order, setOrder] = useState({})
    const [showUpdate, setShowUpdate] = useState(false);
    const [billingForm, setBillingForm] = useState({
        billingfirstname: "",
        billinglastname: "",
        billingstreet: "",
        billingcity: "",
        billingstate: "",
        billingzip: ""
    });

    const [completeOrder, setCompleteOrder] = useState(false);
    const [updateQuantity, setUpdateQuantity] = useState(false)

    const [updateRecieved, setUpdateRecieved] = useState(0);

    const [itemRemoved, setItemRemoved] = useState(0);




    useEffect(() => {
        fetchActiveOrder(setOrder);

    }, [updateRecieved]);

    useEffect(() => {
        setHomeFalse()

    }, [])


    function handleFormUpdate(e) {
        setBillingForm({ ...billingForm, [e.target.name]: e.target.value });
    }

    async function removeLineItem(lineItemId) {
        const deletedItem = await removeLineItemByID(lineItemId);
        setUpdateRecieved(updateRecieved + 1)
        // await fetchActiveOrder(setOrder, setUpdatedQtys);
    }



    if (order.id) {
        // console.log("THIS IS THE ORDER: ", order)


        return (

            <div className='cart-order-container'>
                {/* {console.log("THIS IS ORDER ABOVE THE BACK TO BOOKS BUTTON IN CART: ", order)} */}
                <Link to='/books'>
                    <button className="button" > Back to Books
                    </button>
                </Link>



                {(order.lineItems != 0 ?
                    <div id="product-box" className="form-group centered w-100 cart-item">
                        <h1 className='cartItemsHead'>Cart Items</h1>
                        <div id="lineItems" className="container cartItemsContainer">
                            {order.lineItems.map((lineItem, index) => {

                                return <LineItem lineItem={lineItem} key={lineItem.id} removeLineItem={removeLineItem} token={token} updateRecieved={updateRecieved} setUpdateRecieved={setUpdateRecieved} itemRemoved={itemRemoved} setItemRemoved={setItemRemoved} fetchActiveOrder={fetchActiveOrder} setOrder={setOrder} />

                            })}
                        </div>
                    </div>
                    : <div className='cartIsEmpty'>
                        <h2>Cart is empty</h2>
                    </div>)}

                {order.lineItems != 0 ?
                    <div className='shippingInfoButton'>
                        <Link to='/shipping'>
                            <button className="button">Billing/Shipping</button>
                        </Link>
                    </div> : null}

            </div>
        )
    } else {
        return (
            <div className='cartContainer'>

                <div className="form-group centered">
                    <br />
                    <h2 className="empty-cart">
                        <b className="p-3 mb-5 rounded"> Your shopping cart is empty </b>
                    </h2>
                </div>
                <br />
                <Link to="/books" className="button">
                    Shop Now
                </Link>
                {/* <div className="centered">
                    <button className='button'>Shop Now</button>
                </div> */}
            </div>)
    }
}

export default Cart;