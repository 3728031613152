import { BASE_URL } from '../constants';


import axios from 'axios';
//import { getAllProducts } from '../../db/product';

// export async function getSomething() {
//   try {
//     const { data } = await axios.get('BASE_URL');
//     return data;
//   } catch (error) {
//     throw error;
//   }
// }

//******* USER API CALLS *******/

// test call to grab users info (token and to see if logged in)
export const getUser = async (token) => {
  if (!token) {
    return null;
  }

  try {
    const { data } = await axios.get(`${BASE_URL}/users/me`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
    })
    // console.log("Current user from API index file: ", data);
    return data;
  } catch (error) {
    console.error("ERROR fetching current user!!! 🤦‍♂️ FE-API getUser");
  }
}

// This function will get all users from db
export async function getAllUsers() {
  const token = localStorage.getItem('token');

  if (!token) {
    return false;
  }

  try {
    const { data } = await axios.get(`${BASE_URL}/users`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log("ALL USERS: ", data)

    return data;
  } catch (error) {
    console.error("ERROR getting all users!!! FE-API getAllUsers");
    throw error;
  }
}

export const deleteUser = async (userId) => {

  try {
    const result = await axios.delete(`${BASE_URL}/users/${userId}`);
    // console.log("Deleted user is: ", result)

    return result;
  }

  catch (error) {
    console.error("ERROR deleting user by id!!! - FE-API deleteUser");
    throw error;
  }
}



export const updateUser = async (userId, newfirstname, newlastname, newemail, newstreet, newcity, newstate, newzip, newphone) => {
  try {
    const { data } = await axios.patch(`${BASE_URL}/users/${userId}`, {
      firstname: newfirstname,
      lastname: newlastname,
      email: newemail,
      street: newstreet,
      city: newcity,
      state: newstate,
      zip: newzip,
      phone: newphone
    })
    return data;

  }
  catch (error) {
    console.error("Trouble updating user- FE-API updateUser", error)
  }
}

// This function registers a new user
export const registerUser = async (setToken, setUser, setIsAdmin, firstname, lastname, password, verifyPassword, email, street, city, state, zip, phone) => {

  try {
    if (password !== verifyPassword) {
      alert("Passwords DO NOT match!!! 🤦‍♂️");
      return;
    }
    const result = await axios.post(`${BASE_URL}/users/register`, {
      firstname,
      lastname,
      password,
      email,
      street,
      city,
      state,
      zip,
      phone,
    })
    // console.log(result);
    const currentUser = result.data.user;
    const token = result.data.token;
    const adminStatus = result.data.user.is_admin;
    // console.log("New registered user is: ", currentUser);
    // console.log("Token is: ", token);
    // console.log("Admin status: ", adminStatus);
    setToken(token);
    setUser(currentUser);
    setIsAdmin(adminStatus);
    localStorage.setItem("token", token);
    localStorage.getItem("token");
    localStorage.setItem("user", currentUser);
    localStorage.getItem("user");
    localStorage.setItem("isAdmin", adminStatus);
    localStorage.getItem("isAdmin");

    if (result.error) throw result.error;
  }

  catch (error) {
    console.error('ERROR registering new user!!! 🤦‍♂️ - FE-API registerUser');
    throw error;
  }
}

// This function logs in a registered user
export const loginUser = async (email,
  password,
  setToken,
  setUser,
  setIsAdmin, orderId
) => {

  try {
    const result = await axios.post(`${BASE_URL}/users/login`, {
      email,
      password,
      orderId
    })

    // console.log(result);
    const token = result.data.token;
    const currentUser = result.data.user;
    const adminStatus = result.data.user.is_admin;
    const activeOrderId = currentUser.activeorderid;
    // console.log("Current User is: ", currentUser);
    // console.log("Admin status is: ", adminStatus);
    // console.log("Active Order Id", activeOrderId)
    setToken(token);
    setUser(currentUser);
    setIsAdmin(adminStatus);
    localStorage.setItem("token", token);
    localStorage.getItem("token");
    localStorage.setItem("user", currentUser);
    localStorage.getItem("user");
    localStorage.setItem("isAdmin", adminStatus);
    localStorage.getItem("isAdmin");
    if (activeOrderId) {
      localStorage.setItem("ActiveOrderId", activeOrderId);
      localStorage.getItem("ActiveOrderId");
    }
    if (result.error) throw result.error;
  }

  catch (error) {
    console.error("ERROR logging in user!!! 🤦‍♂️ - FE-API loginUser");
    return "Invalid credentials. Try again";
  }
}

//************ PRODUCT API CALLS ***************/


// This function will fetch all the products in the database.
export const getAllProducts = async () => {
  try {
    const data = await axios.get(`${BASE_URL}/products`);
    // console.log('All products: ', data);

    return data;
  }

  catch (error) {
    console.error('ERROR fetching all products!!! 🤦‍♂️ - FE-API allProducts');
    throw error;
  }
}

// This function fetches a single product from the database by it's productId.
export const getProductById = async (productId) => {

  try {
    const { data } = await axios.get(`${BASE_URL}/products/${productId}`);
    // console.log('The product by id is: ', data);
    return data;
  }

  catch (error) {
    console.error("ERROR getting product by productId!!! 🤦‍♂️ - FE-API getProductById");
    throw error;
  }
}

//This function fetches products with a certain seriesId
export const getProductsBySeriesId = async (seriesId) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/products/series/${seriesId}`);
    // console.log('The products by series id are: ', data);
    return data;
  } catch (error) {
    console.error("ERROR getting products by seriesId!!! 🤦‍♂️ - FE-API getProductById");
    throw error;
  }
}

// This function deletes a single product by it's id
export const deleteProductById = async (productId) => {

  try {
    const result = await axios.delete(`${BASE_URL}/products/${productId}`);
    // console.log("Deleted product is: ", result)

    return result;
  }

  catch (error) {
    console.error("ERROR deleting product by id!!! 🤦‍♂️ - FE-API deleteProductById");
    throw error;
  }
}

// This function creates a new product
export const createProduct = async (name, description, paragraphs, quote, price, inventory_qty, img_url, series_id, vol_num, kindle_link, audio_link, print_link, in_series, series_name) => {
  try {
    const result = await axios.post(`${BASE_URL}/products`, {
      name,
      description,
      paragraphs,
      quote,
      price,
      inventory_qty,
      img_url,
      series_id,
      vol_num,
      kindle_link,
      audio_link,
      print_link,
      in_series,
      series_name

    })
    // console.log("New product is: ", result);

    return result;
  }

  catch (error) {
    console.error("ERROR creating new product!!! 🤦‍♂️ - FE-API createProduct");
    throw error;
  }
}

export const updateProduct = async (productId, name, description, paragraph_one, paragraph_two, paragraph_three, paragraph_four, paragraph_five, paragraph_six, paragraph_seven, paragraph_eight, paragraph_nine, paragraph_ten, quote, price, inventory_qty, img_url, kindle_link, audio_link, print_link, in_series, series_name) => {
  try {
    const { data } = await axios.patch(`${BASE_URL}/products/${productId}`, {
      name: name,
      description: description,
      paragraph_one: paragraph_one,
      paragraph_two: paragraph_two,
      paragraph_three: paragraph_three,
      paragraph_four: paragraph_four,
      paragraph_five: paragraph_five,
      paragraph_six: paragraph_six,
      paragraph_seven: paragraph_seven,
      paragraph_eight: paragraph_eight,
      paragraph_nine: paragraph_nine,
      paragraph_ten: paragraph_ten,
      quote: quote,
      price: price,
      inventory_qty: inventory_qty,
      img_url: img_url,
      kindle_link: kindle_link,
      audio_link: audio_link,
      in_series: in_series,
      series_name: series_name,
      print_link: print_link
    })
    // console.log("Updated product is: ", data)
    return data;
  }
  catch (err) {
    console.error("Trouble updating product - FE-API updateProduct", err)
  }
}

// Submit a review
export const submitReviewForProduct = async (token, productId, title, description, stars) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/products/${productId}/reviews`,
      {
        productId: productId,
        title: title,
        description: description,
        stars: stars
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        }
      })
    return data;
  } catch (error) {
    console.error("Error creating a review for a product", error);
    throw error;
  }
}

export const deleteReview = async (token, productId, title, description, stars) => {

  try {
    const result = await axios.delete(`${BASE_URL}/products/${productId}/reviews`,
      {
        productId: productId,
        title: title,
        description: description,
        stars: stars
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        }
      }
    );

    return result;
  }

  catch (error) {
    console.error("ERROR deleting product by id!!! 🤦‍♂️ - FE-API deleteProductById");
    throw error;
  }
}

//********* ORDERS **********/

export const createOrder = async (token) => {
  let headers = {};
  if (token) {
    headers = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      }
    }
  }
  try {
    const { data } = await axios.post(`${BASE_URL}/orders`, {}, headers)
    return data.id;
  } catch (error) {
    console.error("Error creating order", error);
    throw error;
  }
}

export const addProductToOrder = async (token, orderId, productId, quantity) => {
  let headers = {};
  if (token) {
    headers = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      }
    }
  }
  try {
    const { data } = await axios.post(`${BASE_URL}/orders/${orderId}/products/${productId}`,
      {
        quantity: quantity
      }, headers);
  } catch (error) {
    console.error("Error adding product to order", error);
    throw error;
  }
}

export const updateLineItem = async (quantity, lineItemId, token) => {
  let headers = {};
  if (token) {
    headers = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      }
    }
  }
  try {
    const { data } = await axios.patch(`${BASE_URL}/orders/lineitem/${lineItemId}`,
      {
        quantity: quantity
      }, headers);
  } catch (error) {
    console.error("Error updating lineitem", error);
    throw error;
  }

}

export const getOrderById = async (orderId, setOrder) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/orders/${orderId}`, {}, {});
    // console.log("Got Order", data);
    setOrder(data);
  } catch (error) {
    console.error("Error getting order by Id")
    throw error;
  }
}

export const getAllOrders = async () => {

  try {
    const data = await axios.get(`${BASE_URL}/orders`);
    // console.log('All orders: ', data);

    return data;
  }

  catch (error) {
    console.error('ERROR fetching all orderss!!! 🤦‍♂️ - FE-API getAllOrders');
    throw error;
  }
}

// This function deletes a single order by it's id
export const deleteOrderById = async (orderId) => {

  try {
    const result = await axios.delete(`${BASE_URL}/orders/${orderId}`);
    // console.log("Deleted order is: ", result)

    return result;
  }

  catch (error) {
    console.error("ERROR deleting order by id!!! - FE-API deleteOrderById");
    throw error;
  }
}

export const removeLineItemByID = async (lineItemId) => {
  try {
    const { data } = await axios.delete(`${BASE_URL}/orders/lineitem/${lineItemId}`, {}, {})
  } catch (error) {
    console.error("Error deleting line item", error);
    throw error;
  }
}
export const saveOrderToUser = async (token, orderId) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/users/saveorder/${orderId}`, {},
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        }
      })
  } catch (error) {
    console.error("Error saving the logged off user order")
    throw error;
  }
}

export const closeOrderById = async (orderId) => {
  try {
    const { data } = await axios.patch(`${BASE_URL}/orders/complete/${orderId}`, {}, {});
  } catch (error) {
    console.error("Error completing order");
    throw error;
  }
}

export const submitOrderById = async (orderId, billingForm) => {
  // console.log("API SUBMIT ORDER BY ID: ", orderId)
  try {
    const { data } = await axios.patch(`${BASE_URL}/orders/submitted/${orderId}`, billingForm);
  } catch (error) {
    console.error("Error submitting order");
    throw error;
  }
}

/* Site Content */
export const getContent = async () => {
  try {
    const data = await axios.get(`${BASE_URL}/sitecontent`);
    // console.log('SITE CONTENT: ', data);

    return data;
  }

  catch (error) {
    console.error('ERROR fetching site content!!! 🤦‍♂️ - FE-API getcontent');
    throw error;
  }
}

export const updateContent = async (content) => {
  try {
    // console.log("API UPDATECONTENT, ", content)
    const { data } = await axios.patch(`${BASE_URL}/sitecontent/`, content)
    // console.log("Updated content is: ", data)
    return data;
  }
  catch (err) {
    console.error("Trouble updating content - FE-API updateContent", err)
  }
}


/* NEWS */
export const createNews = async (headline, paragraphs, img_url, news_link, link_description) => {
  try {
    const result = await axios.post(`${BASE_URL}/news`, {
      headline,
      paragraphs,
      img_url,
      news_link,
      link_description
    })
    // console.log("New news is: ", result);

    return result;
  }

  catch (error) {
    console.error("ERROR creating new news!!! :( - FE-API createNews");
    throw error;
  }
}


export const getNews = async () => {
  try {
    const data = await axios.get(`${BASE_URL}/news`);
    // console.log('SITE NEWS: ', data);

    return data;
  }

  catch (error) {
    console.error('ERROR fetching site news!!! 🤦‍♂️ - FE-API getNews');
    throw error;
  }
}

export const updateNews = async (news) => {
  try {
    // console.log("API UPDATE NEWS, ", news)
    const { data } = await axios.patch(`${BASE_URL}/news/`, news)
    // console.log("Updated news is: ", data)
    return data;
  }
  catch (err) {
    console.error("Trouble updatingnews - FE-API updateNews", err)
  }
}

/* --- COMING SOON --- */

export const createComingSoon = async (mainHead, headline, paragraphs, img_url, kindle_url, goodReads_url) => {
  try {
    const result = await axios.post(`${BASE_URL}/comingsoon`, {
      mainHead,
      headline,
      paragraphs,
      img_url,
      kindle_url,
      goodReads_url
      
    })
    // console.log("New coming soon is: ", result);
    return result;
  }
  catch (error) {
    console.error("ERROR creating new coming soon!!! :( - FE-API createComingSoon");
    throw error;
  }
}

export const getComingSoon = async () => {
  try {
    const data = await axios.get(`${BASE_URL}/comingsoon`);
    console.log('SITE COMING SOON: ', data);
    return data;
  }
  catch (error) {
    console.error('ERROR fetching coming soon!!! 🤦‍♂️ - FE-API getComingSoon');
    throw error;
  }
}

export const updateComingSoon = async (coming) => {
  try {
    // console.log("API UPDATE COMING SOON: ", coming)
    const { data } = await axios.patch(`${BASE_URL}/comingsoon/`, coming)
    // console.log("Updated coming soon is: ", data)
    return data;
  }
  catch (err) {
    console.error("Trouble updating coming soon - FE-API updateComingSoon", err)
  }
}

