import React from 'react';
import {Link} from 'react-router-dom';

function SeriesRow({ seriesBooks, seriesTitle }) {

    const sortedBooks = seriesBooks.sort((a, b) => {
        if(a.id > b.id){
            return 1
        } else if(a.id < b.id) {
            return -1
        } else {
            return 0
        }

    })

    return (
        <div className="seriesRow">
            <h4 className='seriesHead'>{seriesTitle}</h4>
            <div className="books-container">
                {/* <Row id="product" > */}
                {sortedBooks.map((product, index) => {
                    let href = `/books/${product.id}`

                    // console.log('PRODUCT FROM SERIES ROW: ', product)
                    
                    return (

                        <div>
                            <div className={"card product-card" + (product.name === "Wrath: Special Edition" ? '-special' : ' ')}>
                                <Link to={href} >
                                    <img src={product.img_url} alt="Product Cover" className={"card-image" + (product.name === "Wrath: Special Edition" ? '-special' : ' ')}
 />

                                </Link>
                            </div>
                        </div>

                    )

                })}
            </div>
        </div>
    )

}

export default SeriesRow;